/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Chart from 'react-apexcharts';

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";
import Header from '../resuableComponent/header';
import SideNav from '../resuableComponent/sidenav';
import BasicLineColumn from "../common/apexChart/lineChart";

import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";

/* Data */
import { 
  getDnsDetails 
} from "../../actions/sub-domain";
import { 
  getSubDomainDetails, 
  getSubDomainDetailsTotal, 
  removeRemediationData, 
  addRemediationData, 
  getSubDomainDetailsGraph, 
  getSubDomainDetailsAction 
} from "../../actions/sub-domain";


// Subdomain Health PAGE
const Domain = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const myRef = useRef(null);
      
    const auth = useSelector((state) => state?.auth?.user);
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector(state => 
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector(state => 
      state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );
    const dnsDetailsArray = useSelector(state => state?.subDomain?.subdomainDnsDetails?.data?.data);
    const dnsDetailsTotal = useSelector(state => state?.subDomain?.subdomainDnsDetails?.data);
    const oldDnsDetails = useSelector(state => state?.subDomain?.subDomainDetailGraph?.data);

  // =============== State Management ===============
    // PAGINATION_AND_DISPLAY
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [containerClass, setContainerClass] = useState("data-container");

    // FILTERING_AND_SEARCH
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("all");
    const [filterData, setfilterData] = useState("all");
    const [domainFilter, setDomainFilter] = useState("all");

    // MODAL_MANAGEMENT
    const [isModal, setModal] = useState("");

    // ACTION_MANAGEMENT
    const [action, setAction] = useState("");
    const [actionNote, setActionNote] = useState("");
    const [forValue, setForValue] = useState("forever");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);

    // IDENTIFIERS_AND_METADATA
    const [checkPath, setCheckPath] = useState('');
    const [lookalikeAppId, setLookalikeAppId] = useState([]);

  // ====== COMPUTED VALUES & CONSTANTS ======
    // URL Parameters
    const modeValue = new URLSearchParams(location?.search).get('mode');
    const queryParameters = new URLSearchParams(window.location.search);
    const vendorName = sessionStorage.getItem('vendorName');
  
    // Domain processing
    let domain = client?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    if (domain?.charAt(domain.length - 1) == "/") {
      domain = [domain.substr(0, domain.length - 1)];
      // domain.unshift("all");
    } else {
      domain = [domain];
      // domain.unshift("all");
    }
    if (client?.domains && client.domains.length > 0) {
      domain = client.domains;
      // domain.unshift("all");
    }
    // Filter processing
    let distinctFilter = ["all", "active", "inactive", "dangling"];

    // Data processing
    let mapData = dnsDetailsArray;
    let currentTableData = [];
    if (mapData && mapData.length > 0) {
      currentTableData = mapData;
    }

  // =============== Effect Hooks ===============
    // Update path when location changes
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const statusParam = params.get('status');
      setfilterData(statusParam);
    }, []);
    useEffect(() => {
      setCheckPath(location.search)
    }, [location.search]);

    // Fetch total data
    useEffect(() => {
      getSubDomainDetailsTotal(id, dispatch)
    }, [id, dispatch]);
    
    // Fetch graph data
    useEffect(() => {
      getSubDomainDetailsGraph(id, dispatch)
    }, [id, dispatch]);
    
    // Fetch data
    useEffect(() => {
      getSubDomainDetails(id, dispatch, page, category, filterData, search, domainFilter);
    }, [id, dispatch, page, category, filterData, search, domainFilter]);
    
    // Update total items when state changes
    useEffect(() => {
      setTotalItems(dnsDetailsTotal?.totalDomains);
    }, [dnsDetailsTotal?.totalDomains]);
    
    // Update container class when state is loaded
    useEffect(() => {
      if (dnsDetailsArray) {
        setTimeout(() => {
          setContainerClass("data-container active");
        }, 300);
      }
    }, [dnsDetailsArray]);

  // ========= Handlers / Functions =========
    
    // Modal handlers
    const addModal = () => {
      setModal("Form Wizard modal");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item._id);
      if (selectedIds.length > 0 || selectedIds.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id, data) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======
    // Handle Snooze action
    const snooze = (_id, event) => {
      if (event == "markAsRemidiated") {
        let ids = [];
        ids.push(_id);
        const data = {
          action: "markAsRemidiated",
          subDomainIds: selectedIds == 0 ? ids : selectedIds,
          actionNote: "",
          for: "forever",
        };
        getSubDomainDetailsAction(
          id, dispatch, page, category, filterData, search, domainFilter, data
        );
        setSelectedValue("pleaseSelect");
        setActionNote("");
        setSelectedIds([]);
      }
    };
    // Handle Unsnooze action
    const Unsnoozed = (_id) => {
      let ids = [];
      ids.push(_id);
      const data = {
        action: 'resetAction',
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
      };
      getSubDomainDetailsAction(
        id, dispatch, page, category, filterData, search, domainFilter, data
      );
      setSelectedIds([]);
    }
    // Handles Form Submission for Snooze/Unsnooze
    const submit = () => {
      const data = {
        action: action,
        subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
        actionNote: actionNote,
        for: forValue,
      };
      getSubDomainDetailsAction(
        id, dispatch, page, category, filterData, search, domainFilter, data
      );
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote('');
      setSelectedIds([]);
    };
  // ====== END Snooze/Unsooze Function ======

  // ====== Export/Download Functions ======
    // Handle file download from API
    const downloadFile = async () => {
      try {
        const session = localStorage.getItem("session");
        axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
        const response = await axios.get(
          `${API_URL}/getSubDomainDetails/${id}/excel`,
          { responseType: "blob" }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Sub-Domain Details.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    };
  // ====== END Export/Download Functions ======

  // ======= Remediation List Bucket =======
    // Remove item from remediation bucket
    const removeToCart = (_id) => e => {
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to remove this from the Remediation List Panel?",
        type: "red",
        buttons: {
          ok: {
            text: "Remove",
            btnClass: "btn-danger",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              removeRemediationData(_id, dispatch, id).then((res) => {
                getSubDomainDetails(id, dispatch, page, category, filterData, search, domainFilter);
              })
            },
          },
          cancel: function () { }
        },
      });
    }
    // Adds item to remediation bucket
    const addToCart = (row) => e => {
      let data = {
        "collectionName": 'deltasubdomaindetails',
        "organisationId": id,
        "recordId": row?._id,
        "moduleName": 'sub_domain'
      }
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to add this to the Remediation List Panel ?",
        type: "green",
        buttons: {
          ok: {
            text: "Add",
            btnClass: "btn-green",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              addRemediationData(data, dispatch).then((res) => {
                getSubDomainDetails(id, dispatch, page, category, filterData, search, domainFilter);
              })
            },
          },
          cancel: function () { }
        },
      });
    }
  // ===== END Remediation List Bucket =====

  return (
    <div>
      {/* <Header type="security" />
      <SideNav type="security" /> */}
      {dnsDetailsArray ?
        <div className={containerClass}>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9'>
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {oldDnsDetails && (
                    <BasicLineColumn
                      dates={oldDnsDetails?.dates}
                      ipArray={oldDnsDetails?.scanDetails}
                      color={["#b0f127"]}
                      yAxis="Records Found"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <div class="row_boxes row_boxes_table">
                <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                        setfilterData("all");
                        setDomainFilter('all')
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                        setfilterData("all");
                        setDomainFilter('all')
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Risk Accepted
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("false-positive");
                        setPage(1);
                        setfilterData("all");
                        setDomainFilter('all')
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      False Positive
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                        setfilterData("all");
                        setDomainFilter('all')
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Remediated
                    </a>
                  </li>
                </ul>
                <div className="row" ref={myRef}>
                  <div className="col-sm-3">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => { setfilterData(e.target.value); setPage(1) }}
                    >
                      {distinctFilter?.map((row) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={domainFilter}
                      onChange={(e) => { setDomainFilter(e.target.value); setPage(1) }}
                    >
                      <option value='all'>All</option>
                      {domain?.map((row, index) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setPage(1);
                        }}
                      />
                    </div>
                  </div>
                  {modeValue == "report-mode" && <>
                    <div className="col-sm-3">
                      {currentTableData?.length === 0 ? null : (
                        <span>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              downloadFile();
                            }}
                            href="/add-report"
                            style={{
                              float: "right",
                              color: "black",
                              fontWeight: "bold",
                            }}
                            className="btn btn-primary mr-1"
                          >
                            <i
                              className="fa fa-file-excel-o"
                              style={{ color: "black" }}
                            ></i>
                          </a>
                        </span>
                      )}
                    </div>
                  </>}
                </div>
                <div class="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>#</th>
                        <th>Status</th>
                        <th>Domain Name</th>
                        <th>IP Address</th>
                        <th>Service</th>
                        <th style={{ textAlign: "center" }}>Last modified on</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                          <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        const pageCount = (page - 1) * 10 + 1;
                        return (
                          <tr>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(
                                      row?._id
                                    )}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>{pageCount + index}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span
                                className={
                                  row?.isActive == true
                                    ? "badge badge-success"
                                    : row?.isDangling == true
                                      ? "badge badge-danger"
                                      : "badge badge-warning"
                                }
                              >
                                {row?.isActive == true
                                  ? "Active"
                                  : row?.isDangling == true
                                    ? "Dangling"
                                    : "In Active"}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "unset", color: "white" }}>
                              <a
                                href={`https://${row?.subDomainName}`}
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                {`${row?.subDomainName}`}
                              </a>
                            </td>
                            {/* <td>{row?.recordType}</td> */}
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.ip}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.server
                                ? row?.server
                                : row?.serverName}
                            </td>
                            <td
                              style={{ whiteSpace: "unset", textAlign: "center" }}
                            >
                              <span className="badge badge-success">
                                {moment(row?.updatedAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </td>
                            {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                              <td>
                                <Box sx={{ width: 200 }}>
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedValue}
                                      onChange={(event) => {
                                        setAction(event.target.value);
                                        snooze(row?._id, event.target.value);
                                      }}
                                    >
                                      <MenuItem value="pleaseSelect">
                                        Snooze Options
                                      </MenuItem>
                                      <MenuItem
                                        value="riskAccepted"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([row._id]);
                                        }}
                                      >
                                        Risk Accepted
                                      </MenuItem>
                                      <MenuItem
                                        value="markAsFalsePositive"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([row._id]);
                                        }}
                                      >
                                        False Positive
                                      </MenuItem>
                                      <MenuItem value="markAsRemidiated">
                                        Remediated
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                            )}
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" || category === "false-positive") && (
                              <td>
                                <button
                                  className="btn btn-primary btn-sm mr-1 Unsnooze"
                                  onClick={() => Unsnoozed(row?._id)}
                                >
                                  Unsnooze
                                </button>
                              </td>
                            )}
                            {clientType == "Admin" ?
                              <td>
                                {row?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...row
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(row?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>

              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) =>
                      setPage(page
                      )}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote('');
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">Snooze for</label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote('');
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <Loader />
      }

    </div>
  );
}

export default Domain;