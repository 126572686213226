/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";
import BasicLineColumn from "../common/apexChart/lineChart";
import NoData from "../common/noDataComponetnt";



const NewDashboard = () => {
    const dispatch = useDispatch();
    const cartDispatch = useDispatch();
    const location = useLocation();

    const auth = useSelector((state) => state?.auth?.user);
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector(
      (state) =>
        state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector(
      (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );

  // =============== State Management ===============
    // Pagination & Filtering
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("all");
    const [filterData, setfilterData] = useState("all");
    const [containerClass, setContainerClass] = useState("data-container");
    const [totals, setTotals] = useState({});
    const [state, setState] = useState(false);

    // Modal and Action States
    const [isModal, setModal] = useState("");
    const [forValue, setForValue] = useState("forever");
    const [lookalikeAppId, setLookalikeAppId] = useState([]);
    const [actionNote, setActionNote] = useState("");
    const [action, setAction] = useState("");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [addedRemediations, setAddedRemediations] = useState([]);
    const [checkPath, setCheckPath] = useState('');

    // Selection States
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);

   
    
  // ====== COMPUTED VALUES & CONSTANTS ======

  // =============== Effect Hooks ===============
    // Update path when location changes
    useEffect(() => {
      setCheckPath(location.search);
    }, [location.search]);
    
    // Data fetch effect
    useEffect(() => {
      // TODO: Add your data fetching action here
      // fetchData(id, dispatch, page, category, filterData);
    }, [id, dispatch, page, category, filterData]);


  // ========= Handlers / Functions =========
    // ========= Handlers / Functions =========
    // Modal handlers
    const addModal = () => {
      setModal("Form Wizard modal");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item._id);
      if (selectedIds.length > 0 || selectedIds.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id, data) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======

  // ====== END Snooze/Unsooze Function ======


  const handleDownload = () => {
    // Implement your download logic here
  };

  return (
    <div>
      {/* Main Content */}
      <div className="data-container active">
       {/*<div className={containerClass}>*/}
        {/* Header Section */}
        <div className="row clearfix no_margin" style={{ marginTop: '20px' }}>
          <div className="col-sm-2"></div>
            <div className="col-sm-10">
              
              {/* Top Row */}
              <div className="row">
              

              

              </div>

              {/* Bottom Row - Status Indicators */}
              <div className="row mt-4">
                <div className="col-sm-12">
                <div className="d-flex justify-content-left">
                  {/* Status Box 1 */}
                  <div className="col-md-4">
                    <div className="card mb-2">
                  <div className="body status-indicator" style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center">
                    <div className={`status-light ${
                      !totals?.encryptionStatus ? 'yellow' :
                      totals?.encryptionStatus === 'safe' ? 'green' : 'red'
                    }`}>
                      <div className="status-pulse"></div>
                    </div>
                    <div className="status-text ml-3">
                    <h6 className="mb-1">Encryption Status</h6>
                    <p className={!totals?.encryptionStatus ? 'text-muted mb-0' : 'text-white mb-0'}>
                      {totals?.encryptionStatus || 'Not Scanned'}
                    </p>
                    </div>
                    </div>
                  </div>
                    </div>
                  </div>  
                  {/* Status Box 2 */}
                  <div className="col-md-4">
                    <div className="card mb-2">
                  <div className="body status-indicator" style={{ cursor: 'pointer' }}>
                  <div className="d-flex align-items-center">
                    <div className={`status-light ${
                      !totals?.pqrReadiness ? 'yellow' :
                      totals?.pqrReadiness === 'ready' ? 'green' : 'red'
                    }`}>
                      <div className="status-pulse"></div>
                    </div>
                    <div className="status-text ml-3">
                    <h6 className="mb-1">PQR Readiness</h6>
                    <p className={!totals?.pqrReadiness ? 'text-muted mb-0' : 'text-white mb-0'}>
                      {totals?.pqrReadiness || 'Not Assessed'}
                    </p>
                    </div>
                    </div>
                  </div>
                    </div>
                  </div>
                  <style jsx>{`
                            .status-indicator {
                            padding: 15px;
                            }
                            .status-light {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            position: relative;
                            box-shadow: 0 0 10px rgba(0,0,0,0.2);
                            }
                            .status-pulse {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            animation: pulse 2s infinite;
                            opacity: 0.6;
                            }
                            .green { 
                            background-color: #2ecc71;
                            border: 2px solid #27ae60;
                            }
                            .yellow { 
                            background-color: #f1c40f;
                            border: 2px solid #f39c12;
                            }
                            .red { 
                            background-color: #e74c3c;
                            border: 2px solid #c0392b;
                            }
                            .grey { 
                            background-color: #95a5a6;
                            border: 2px solid #7f8c8d;
                            }
                            @keyframes pulse {
                            0% {
                              transform: scale(1);
                              opacity: 0.6;
                            }
                            70% {
                              transform: scale(1.5);
                              opacity: 0;
                            }
                            100% {
                              transform: scale(1);
                              opacity: 0;
                            }
                            }
                            .ml-3 {
                            margin-left: 15px;
                            }
                          `}</style>
                    </div>
                </div>
              </div>    
              
            </div>

          </div>

        {/* Main Content Section */}
        <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
          <div className="col-sm-2"></div>
          <div className="col-sm-9" style={{ padding: "0" }}>
            
            {/* Snooze Category Tabs */}
            <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
              {["all", "risk-accepted", "false-positive", "remediated"].map((cat) => (
                <li className="nav-item" key={cat}>
                  <a
                    className={`nav-table-tab nav-link ${category === cat ? "active" : ""}`}
                    onClick={() => {
                      setCategory(cat);
                      setPage(1);
                      setSelectedIds([]);
                    }}
                  >
                    {/*{cat === "all" ? "Current" : cat.charAt(0).toUpperCase() + cat.slice(1).replace("-", " ")}*/}
                    {cat === "all" 
                      ? "Current" 
                      : cat.split('-')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                  </a>
                </li>
              ))}
            </ul>

            {/* Filters Section */}
            <div className="row mb-2">
              {/* TODO: Add your specific filters here */}
            </div>

            {/* Data Table */}
            <div className="row_boxes row_boxes_table">
              <div className="table_box">
                <table className="table">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && 
                       (category === "all" || category === "risk-accepted" || 
                        category === "false-positive") && (
                        <th>
                          <input
                            type="checkbox"
                            checked={selectedIds == 0 ? false : multiCheckTrue}
                            onClick={() => multicheck(/* your data */)}
                            className="checkUncheck"
                          />
                        </th>
                      )}
                      {/* TODO: Add your specific table headers here */}
                        <th>#</th>
                        <th>Type</th>
                        <th style={{ width: "80" }}>Title</th>
                        <th style={{ width: "80" }}>Status</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* TODO: Add your specific table rows here */}
                    
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination */}
            <div className="dataTables_paginate">
              {totalItems > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={totalItems}
                  pageSize={PageSize}
                  onPageChange={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        </div>

        {/* Modal */}
        <div className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""}`}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {/* Modal content here */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;