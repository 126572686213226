/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";

import KnownsubscribersChart from "../common/apexChart/knownsubscribersChart";

/* Data */
import {
  fetchCVEDetails,
  fetchCVEDetailsGraph,
  fetchCVEDetailsTotal,
  fetchCVEDetailsAction,
} from "../../actions/vulnerability";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData
} from "../../actions/sub-domain";


// Known Vulnerabilities PAGE
const KnownVulnerability = () => {
    const dispatch = useDispatch();
    const cartDispatch = useDispatch();
    const location = useLocation();
    const myRef = useRef(null);

    const auth = useSelector((state) => state?.auth?.user);
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector((state) => 
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector((state) => 
      state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );
    let table = useSelector((state) => state.vulnerability?.cveDetails?.data?.data);
    let totalCount = useSelector((state) => state.vulnerability?.cveTotal?.data);
    let scanCompleted = useSelector((state) => state.vulnerability?.scanCompleted);
    const scanDetails = useSelector((state) => state?.vulnerability?.cveGraph?.data);
    const iPDetails = useSelector((state) => state?.vulnerability?.cveDetails?.scanDetailArray?.ipDetails);
    const pagination = useSelector((state) => state.vulnerability?.cveDetails?.data);
    let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

  // =============== State Management ===============
    // PAGINATION_AND_DISPLAY
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [containerClass, setContainerClass] = useState("data-container");

    // FILTERING_AND_SEARCH
    const [search, setsearch] = useState("");
    const [category, setCategory] = useState("all");
    const [filterData, setfilterData] = useState("all");
    const [ipFilter, setIpFilter] = useState("");

    // MODAL_MANAGEMENT
    const [isModal, setModal] = useState("");

    // ACTION_MANAGEMENT
    const [action, setAction] = useState("");
    const [actionNote, setActionNote] = useState("");
    const [forValue, setForValue] = useState("forever");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);
    const [addedRemediations, setAddedRemediations] = useState([]);

    // IDENTIFIERS_AND_METADATA
    const [lookalikeAppId, setLookalikeAppId] = useState([]);
    const [checkPath, setCheckPath] = useState('');

  // ====== COMPUTED VALUES & CONSTANTS ======
    const modeValue = new URLSearchParams(location?.search).get('mode');

    let ipArray = [];
    for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
      ipArray.push(scanDetails?.scanDetails?.[details]?.length || 0);
    }
    
    let mapData = table;
    let currentTableData = [];
    if (mapData && mapData.length > 0) {
      currentTableData = mapData;
    }

  // =============== Effect Hooks ===============
    // Update path when location changes
    useEffect(() => {
      setCheckPath(location.search)
    }, [location.search]);

    // Fetch total data
    useEffect(() => {
      fetchCVEDetailsTotal(id, dispatch);
    }, [id, dispatch]);
    
    // Fetch graph data
    useEffect(() => {
      fetchCVEDetailsGraph(id, dispatch);
    }, [id, dispatch]);
    
    // Fetch data
    useEffect(() => {
      fetchCVEDetails(id, dispatch, page, PageSize, search, category, ipFilter);
    }, [id, dispatch, page, PageSize, search, category, ipFilter]);

    // Update remediation list when it changes
    useEffect(() => {
      if (remediationList) {
        const updatedRemediations = remediationList.reduce((results, elem) => {
          return { ...results, [elem._id]: elem };
        }, {});
        setAddedRemediations(updatedRemediations);
      }
    }, [remediationList]);

    // Update total items when state changes
    useEffect(() => {
      setTotalItems(pagination?.cveDetailsRecord);
    }, [pagination?.cveDetailsRecord]);

    // Update container class when state is loaded
    useEffect(() => {
      if (table) {
        setTimeout(() => {
          setContainerClass("data-container active");
        }, 300);
      }
    }, [table]);

  // ========= Handlers / Functions =========
    // Scroll handler
    const executeScroll = (val) => {
      setfilterData(val);
      myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
    };

    // Severity calculation helpers for table
    const severity = (val) => {
      if (val < 4) return "Low";
      if (val >= 4 && val < 7) return "Medium";
      if (val >= 7 && val < 9) return "High";
      if (val >= 9) return "Critical";
    };
    const severityBatch = (val) => {
      if (val < 4) return "badge badge-low";
      if (val >= 4 && val < 7) return "badge badge-med";
      if (val >= 7 && val < 9) return "badge badge-high";
      if (val >= 9) return "badge badge-critical";
    };

    // Modal handlers
    const addModal = () => {
      setModal("Form Wizard modal");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item._id);
      if (selectedIds.length > 0 || selectedIds.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id, data) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======
    // Handle Snooze action
    const snooze = (_id, event) => {
      if (event == "markAsRemidiated") {
        let ids = [];
        ids.push(_id);
        const data = {
          action: "markAsRemidiated",
          pvaIds: selectedIds == 0 ? ids : selectedIds,
          actionNote: "",
          for: "forever",
        };
        fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
        setSelectedValue("pleaseSelect");
        setActionNote("");
        setSelectedIds([]);
      }
    };
    // Handle Unsnooze action
    const Unsnoozed = (_id) => {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "resetAction",
        pvaIds: selectedIds == 0 ? ids : selectedIds,
      };
      fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
      setSelectedIds([]);
    };
    // Handles Form Submission for Snooze/Unsnooze
    const submit = () => {
      const data = {
        action: action,
        pvaIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
        actionNote: actionNote,
        for: forValue,
      };
      fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    };
  // ====== END Snooze/Unsooze Function ======

  // ====== Export/Download Functions ======
    // Handle file download from API
    const downloadFile = async () => {
      try {
        const session = localStorage.getItem("session");
        axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
        const response = await axios.get(
          `${API_URL}/cve-details/${id}/excel`,
          { responseType: "blob" }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "IP Vulnerability Details.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    };
  // ====== END Export/Download Functions ======

  // ======= Remediation List Bucket =======
    // Remove item from remediation bucket
    const removeToCart = (_id) => e => {
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to remove this from the Remediation List Panel?",
        type: "red",
        buttons: {
          ok: {
            text: "Remove",
            btnClass: "btn-danger",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              removeRemediationData(_id, cartDispatch, id).then(() => {
                fetchCVEDetails(id, dispatch, page, PageSize, search, category);
              })
            },
          },
          cancel: function () { },
        },
      });
    }

    // Adds item to remediation bucket
    const addToCart = (row) => e => {
      let data = {
        "collectionName": 'delta_cve_details',
        "organisationId": id,
        "recordId": row?._id,
      }
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to add this to the Remediation List Panel ?",
        type: "green",
        buttons: {
          ok: {
            text: "Add",
            btnClass: "btn-green",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              addRemediationData(data, cartDispatch).then(() => {
                fetchCVEDetails(id, dispatch, page, PageSize, search, category);
              })
            },
          },
          cancel: function () { },
        },
      });
    }
  // ===== END Remediation List Bucket =====

  return (
    <div>
      {/* <Header type="security" />
      <SideNav type="security" /> */}

      {table ? (
        <div className={containerClass}>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {scanDetails && (
                    <KnownsubscribersChart
                      dates={scanDetails?.dates}
                      data={scanDetails?.scanDetails}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      setPage(1);
                      setsearch("all");
                      setfilterData("all");
                      setSelectedIds([]);
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      setPage(1);
                      setsearch("all");
                      setfilterData("all");
                      setSelectedIds([]);
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      setPage(1);
                      setfilterData("all");
                      setsearch("all");
                      setSelectedIds([]);
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      setPage(1);
                      setfilterData("all");
                      setsearch("all");
                      setSelectedIds([]);
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    value={ipFilter}
                    onChange={(e) => setIpFilter(e.target.value)}
                    placeholder="Ip Address"
                  />
                </div>
                <div className="col-sm-3">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={filterData}
                    onChange={(e) => {
                      setsearch(e.target.value);
                      setfilterData(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="critical">Critical</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                </div>
                {modeValue == "report-mode" && <>
                  <div className="col-sm-1 d-flex justify-content-end">
                    {currentTableData?.length === 0 ? null : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile();
                        }}
                        style={{ float: "right" }}
                        href="/add-report"
                        className="btn btn-primary mr-1"
                      >
                        <i
                          className="fa fa-file-excel-o"
                          style={{ color: "black" }}
                        ></i>
                      </a>
                    )}
                  </div>
                </>}
              </div>
              <div class="row_boxes row_boxes_table">
                <div class="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>IP Address</th>
                        <th>CVE ID</th>
                        <th>Severity</th>
                        <th>Ageing</th>
                        <th>Description</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row) => {
                        return (
                          <tr>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(
                                      row?._id
                                    )}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td style={{ whiteSpace: "unset" }}>{row?.ip}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.cveId}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span
                                className={
                                  row?.severity === "medium"
                                    ? "badge badge-med"
                                    : row?.severity === "low"
                                      ? "badge badge-low"
                                      : row?.severity === "high"
                                        ? "badge badge-high"
                                        : row?.severity === "critical"
                                          ? "badge badge-critical"
                                          : "N/A"
                                }
                              >
                                {row?.severity}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {moment().diff(
                                moment(row?.cvePublishedAt),
                                "days"
                              )}{" "}
                              days
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.description}
                            </td>
                            {row?.remediationPending == true ? <td>Retest in progress</td> : <>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(
                                            row?._id,
                                            event.target.value
                                          );
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                            </>
                            }
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {clientType == "Admin" ?
                              <td>
                                {row?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...row,
                                      remediationType: "Sub Domain",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(row?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={10}
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
          className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-6 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginRight: "28px" }}
                      >
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          onClick={submit}
                          className="btn btn-round btn-primary mr-1 pull-right"
                        >
                          Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn btn-round btn-default pull-right"
                          style={{ marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
    
  );
};

export default KnownVulnerability;