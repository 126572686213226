/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";
import BasicLineColumn from "../common/apexChart/lineChart";
import NoData from "../common/noDataComponetnt";



const QuantumReadinessOverview = () => {
    const dispatch = useDispatch();
    const cartDispatch = useDispatch();
    const location = useLocation();

    const auth = useSelector((state) => state?.auth?.user);
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector(
      (state) =>
        state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector(
      (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );

  // =============== State Management ===============
    // Pagination & Filtering
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("all");
    const [filterData, setfilterData] = useState("all");
    const [containerClass, setContainerClass] = useState("data-container");
    const [totals, setTotals] = useState({});
    const [state, setState] = useState(false);

    // Modal and Action States
    const [isModal, setModal] = useState("");
    const [forValue, setForValue] = useState("forever");
    const [lookalikeAppId, setLookalikeAppId] = useState([]);
    const [actionNote, setActionNote] = useState("");
    const [action, setAction] = useState("");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [addedRemediations, setAddedRemediations] = useState([]);
    const [checkPath, setCheckPath] = useState('');

    // Selection States
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);

    // Quantum Specific States
    const [discoveredTechnologies, setDiscoveredTechnologies] = useState([]);
    const [isLoadingTechnologies, setIsLoadingTechnologies] = useState(true);

  // ====== COMPUTED VALUES & CONSTANTS ======
    // Default technologies configuration
    const defaultTechnologies = [
      { name: 'RSA', count: 0, icon: 'fa-lock' },
      { name: 'AES', count: 0, icon: 'fa-shield' },
      { name: 'ECC', count: 0, icon: 'fa-key' },
      { name: 'DES', count: 0, icon: 'fa-lock' }
    ];
  // =============== Effect Hooks ===============
    // Update path when location changes
    useEffect(() => {
      setCheckPath(location.search);
    }, [location.search]);
    
    // Data fetch effect
    useEffect(() => {
      // TODO: Add your data fetching action here
      // fetchData(id, dispatch, page, category, filterData);
    }, [id, dispatch, page, category, filterData]);

    // Quantum Encryptions Discovered useEffect hooks
    useEffect(() => {
    const fetchTechnologies = async () => {
        setIsLoadingTechnologies(true);
        try {
            // Replace this with your actual API endpoint
            const response = await axios.get(`${API_URL}/encryption-technologies`);
            if (response.data && response.data.length > 0) {
                // Transform your API response to match the required format
                const technologies = response.data.map(tech => ({
                    name: tech.name,
                    count: tech.count || 0,
                    icon: tech.icon || 'fa-lock' // default icon if none provided
                }));
                setDiscoveredTechnologies(technologies);
            }
        } catch (error) {
            console.error('Error fetching encryption technologies:', error);
            // If there's an error, we'll fall back to default technologies
            setDiscoveredTechnologies([]);
        } finally {
            setIsLoadingTechnologies(false);
        }
    };
    fetchTechnologies();
    }, [totals]); // Add any other dependencies you need

  // ========= Handlers / Functions =========
    // ========= Handlers / Functions =========
    // Modal handlers
    const addModal = () => {
      setModal("Form Wizard modal");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item._id);
      if (selectedIds.length > 0 || selectedIds.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id, data) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======

  // ====== END Snooze/Unsooze Function ======


  const handleDownload = () => {
    // Implement your download logic here
  };

  // Helper function to render a single technology box
  const renderTechnologyBox = (tech) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={tech.name}>
        <div className="card mb-2">
            <div className="body p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h6 className="mb-1">{tech.name}</h6>
                        <h5 className="mb-0">{tech.count}</h5>
                        <small className="text-muted">
                            Last updated: {moment().format('YYYY-MM-DD HH:mm:ss')}
                        </small>
                    </div>
                    <i className={`fa ${tech.icon} fa-2x text-muted`}></i>
                </div>
            </div>
        </div>
    </div>
  );

  return (
    <div>
      {/* Main Content */}
      <div className="data-container active">
       {/*<div className={containerClass}>*/}
        {/* Header Section */}
        <div className="row clearfix no_margin" style={{ marginTop: '20px' }}>
          <div className="col-sm-2"></div>
            <div className="col-sm-10">
              
              {/* Top Row */}
              <div className="row">
                {/* Description Section - Left Side  */}
                <div className="col-sm-6">
                <div className="card mb-4">
                  <div className="card-body">
                  <h4 className="card-title">PQR Scanner</h4>
                  <p className="card-text">
                    As quantum computing advances, the threat to current cryptographic systems grows. 
                    Post-Quantum Cryptography (PQC) aims to develop algorithms resistant to quantum attacks, 
                    ensuring the security of sensitive data.<br></br>
                    Organizations must transition to PQC to protect against the "Harvest Now, Decrypt Later" threat, 
                    where encrypted data captured today could be decrypted by quantum computers in the future.
                    <br></br><br></br>
                    To assist in this transition, we offer a scanning tool that can be run on your PC. 
                  </p>
                  <p className="card-text">
                    This tool evaluates your current encryption technologies and assesses your readiness for the post-quantum era.
                    <br></br>Download it now to ensure your systems are quantum-safe!
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={handleDownload}
                    style={{ float: 'right' }}
                  >
                    <i className="fa fa-download mr-2"></i>
                    Download Application
                  </button>
                  </div>
                </div>
                </div>

                {/* Counter Boxes - Right Side */}
                <div className="col-sm-4">
                <div className="row">
                {/* Counter Box 1 */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="card mb-2" onClick={() => window.location.href = '/quantum/domain'}>
                    <div className="body" style={{ cursor: 'pointer' }}>
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {totals?.counter1 || 0}
                          </h5>
                          <small className="text-muted color-white">Domains</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Counter Box 2 */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="card mb-2" onClick={() => window.location.href = '/quantum/system'}>
                    <div className="body" style={{ cursor: 'pointer' }}>
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {totals?.counter2 || 0}
                          </h5>
                          <small className="text-muted color-white">Systems</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Counter Box 3 */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="card mb-2" onClick={() => window.location.href = '/quantum/application'}>
                    <div className="body" style={{ cursor: 'pointer' }}>
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {totals?.counter2 || 0}
                          </h5>
                          <small className="text-muted color-white">Applications</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Counter Box 4 */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="card mb-2" onClick={() => window.location.href = '/quantum/file'}>
                    <div className="body" style={{ cursor: 'pointer' }}>
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {totals?.counter2 || 0}
                          </h5>
                          <small className="text-muted color-white">Files</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Counter Box 5 */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="card mb-2" onClick={() => window.location.href = '/quantum/traffic'}>
                    <div className="body" style={{ cursor: 'pointer' }}>
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {totals?.counter2 || 0}
                          </h5>
                          <small className="text-muted color-white">Connections / EndPoints</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 {/* Counter Box 6 */}
                 <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="card mb-2" onClick={() => window.location.href = '/quantum/code'}>
                    <div className="body" style={{ cursor: 'pointer' }}>
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {totals?.counter2 || 0}
                          </h5>
                          <small className="text-muted color-white">Code Records</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Add more counter boxes as needed */}
                </div>
                </div>
              </div>

              {/* Middle Row - PQR Status Indicator */}
              <div className="row mt-4">
                <div className="col-sm-10">
                <div className="d-flex justify-content-left">
                    <div className="card mb-2">
                      <div className="body status-indicator" style={{ cursor: 'pointer' }}>
                        <div className="d-flex align-items-center">
                          <div className={`status-light ${
                            !totals?.pqrReadiness ? 'yellow' :
                            totals?.pqrReadiness === 'ready' ? 'green' : 'red'
                            }`}>
                          </div>
                            <div className="status-text ml-3">
                              <h6 className="mb-1">Post Quantum Readiness</h6>
                              <p className={!totals?.pqrReadiness ? 'text-muted mb-0' : 'text-white mb-0'}>
                                {totals?.pqrReadiness || 'Not Assessed'}
                              </p>
                            </div>
                        </div>
                      </div>
                    </div>
                 
                  <style jsx>{`
                    .status-indicator {
                      padding: 15px;
                    }
                    .status-light {
                      width: 25px;
                      height: 25px;
                      border-radius: 50%;
                      position: relative;
                      box-shadow: 0 0 10px rgba(0,0,0,0.2);
                    }
                    .status-light::after {
                      content: '';
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      animation: pulse 2s infinite;
                      top: 0;
                      left: 0;
                    }
                    .green { 
                      background-color: #2ecc71;
                      border: 2px solid #27ae60;
                    }
                    .yellow { 
                      background-color: #f1c40f;
                      border: 2px solid #f39c12;
                    }
                    .yellow::after {
                      background-color: #f1c40f;
                    }
                    .red { 
                      background-color: #e74c3c;
                      border: 2px solid #c0392b;
                    }
                    .red::after {
                      background-color: #e74c3c;
                    }
                    @keyframes pulse {
                      0% {
                        transform: scale(1);
                        opacity: 0.7;
                      }
                      50% {
                        transform: scale(1.6);
                        opacity: 0;
                      }
                      100% {
                        transform: scale(1);
                        opacity: 0;
                      }
                    }
                    .ml-3 {
                      margin-left: 15px;
                    }
                  `}</style>
                </div>
                </div>
              </div> 

              {/* Encryption Technologies Section */}
              <div className="row mt-4">
                <div className="col-sm-10">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="card-title">Encryption Technologies Discovered</h4>
                                <small className="text-muted">
                                    Last scan: {moment().format('YYYY-MM-DD HH:mm:ss')}
                                </small>
                            </div>
                            <div className="row">
                                {isLoadingTechnologies ? (
                                    <div className="col-12 text-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {(discoveredTechnologies.length > 0 
                                            ? discoveredTechnologies
                                            : defaultTechnologies.map(tech => ({
                                                ...tech,
                                                count: totals?.[tech.name.toLowerCase()] || 0
                                            }))
                                        ).map(tech => renderTechnologyBox(tech))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              
            </div>
          </div>

       
        {/* Modal */}
        <div className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""}`}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              {/* Modal content here */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantumReadinessOverview;