/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
// NO axios import or @mui imports

import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Chart from 'react-apexcharts';

/* Local Imports */
import Pagination from "../Pagination/Pagination";
// NO API_URL import
import Loader from "../resuableComponent/loader";
import Header from '../resuableComponent/header';
import SideNav from '../resuableComponent/sidenav';
import BasicLineColumn from "../common/apexChart/lineChart";

import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";

/* Data */
import { 
  getDnsDetails 
} from "../../actions/sub-domain";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData
} from "../../actions/sub-domain";


// DNS Health Page
const Domain = () => {
    const dispatch = useDispatch();
    const cartDispatch = useDispatch();
    const location = useLocation();
    const myRef = useRef(null);
      
    // no auth?
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector((state) => 
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector((state) => 
      state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );
    const dnsDetailsArray = useSelector((state) => state.subDomain?.dnsDetails?.data);
    const oldDnsDetails = useSelector((state) => state.subDomain?.dnsDetails?.scanDetailArray);
    const scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);
    const remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

  // =============== State Management ===============
    // PAGINATION_AND_DISPLAY
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [containerClass, setContainerClass] = useState("data-container");

    // FILTERING_AND_SEARCH
    const [search, setSearch] = useState("");
    const [filterData, setfilterData] = useState("all");
    const [domainFilter, cartCertssetDomainFilter] = useState("all");

    // IDENTIFIERS_AND_METADATA
    const [checkPath, setCheckPath] = useState('');

    // ACTION_MANAGEMENT
    const [addedRemediations, setAddedRemediations] = useState([]);
  
  // ====== COMPUTED VALUES & CONSTANTS ======
    const queryParameters = new URLSearchParams(window.location.search);
    const latest = queryParameters.get("latest");
    //console.log(latest,'props.location.search')
    if (latest && filterData == "all")
      setfilterData("Latest")

    // Domain processing
    let domain = client?.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    if (domain?.charAt(domain.length - 1) == "/") {
      domain = [domain.substr(0, domain.length - 1)];
    } else {
      domain = [domain];
    }
    if (client?.domains && client.domains.length > 0) {
      domain = client.domains;
    }
      
    // Filter processing
    const filterValue = dnsDetailsArray?.map((row) => row?.recordType);
    let distinctFilter = filterValue?.filter(
      (item, index, self) => self.indexOf(item) === index
    );
    distinctFilter?.unshift("all");
    distinctFilter?.push("Latest");

    // Filter issues and warnings
    const issuesFound = dnsDetailsArray?.filter(
      (row) =>
        row?.recordType == "spf" &&
        !row?.valid &&
        row?.record?.indexOf("SPF TXT record") == -1 &&
        row?.record?.indexOf("does not exist") == -1
    );
    const warningFound = dnsDetailsArray?.filter(
      (row) =>
        (row?.recordType == "dmarc" && !row?.valid) ||
        (row?.recordType == "MX" && row?.hosts?.length > 0 && !row?.valid)
    );

    // Arrays for charts
    let openPortArray = [];
    let ipArray = [];
    for (let count = 0; count < oldDnsDetails?.scanDetails?.length; count++) {
      const compliantDataOld = oldDnsDetails?.scanDetails?.[count]?.filter(
        (row) => row?.valid
      );
      let compliantDataOldPercentage =
        (compliantDataOld?.length / oldDnsDetails?.scanDetails?.[count]?.length) * 100;
      ipArray.push(oldDnsDetails?.scanDetails?.[count]?.length || 0);
      openPortArray.push(compliantDataOldPercentage?.toFixed(0));
    }

    // Process new domains
    let newAddedDomain = [];
    if (client?.scanCounter > 0) {
      let oldScanData = oldDnsDetails?.scanDetails[client?.scanCounter - 1];
      for (let vuln = 0; vuln < dnsDetailsArray?.length; vuln++) {
        let flag = true;
        for (let oldVuln = 0; oldVuln < oldScanData?.length; oldVuln++) {
          if (dnsDetailsArray[vuln].subDomain == oldScanData[oldVuln].subDomain && 
              dnsDetailsArray[vuln].recordType == oldScanData[oldVuln].recordType) {
            flag = false;
            break;
          }
        }
        if (flag) {
          newAddedDomain.push(dnsDetailsArray[vuln])
        }
      }
      //console.log(newAddedDomain,'scanDetails');
    }

    // Data filtering
    if (domainFilter !== "all") {
      dnsDetailsArray = dnsDetailsArray?.filter((val) => 
        val?.subDomain.indexOf(domainFilter) != -1)
    }

    let mapData =
      filterData === "all"
        ? dnsDetailsArray
        : filterData === "Issues"
          ? issuesFound
          : filterData === "Warnings"
            ? warningFound
            : filterData === "Last 30 days"
              ? recordAddedInLast30Days
              : filterData === "Latest"
                ? newAddedDomain
                : dnsDetailsArray?.filter((row) => row?.recordType == filterData);
    // mapData = mapData?.filter((row) => ((row?.recordType == "spf" && (row?.record?.indexOf("SPF TXT record") == -1 && row?.record?.indexOf("does not exist") == -1)) || (((row?.recordType == "dmarc" && !row?.valid) || (row?.recordType == "MX" && row?.hosts?.length > 0 && !row?.valid)))))
    
    const recordAddedInLast30Days = mapData?.filter(
      (row) =>
        moment(row?.createdAt).isBefore(moment()) &&
        moment(row?.createdAt).isAfter(moment().subtract(90, "days"))
    );

    mapData = mapData?.filter((res) => {
      if (search === "") {
        return res;
      } else if (
        res?.recordType?.toLowerCase()?.indexOf(search.toLowerCase()) != -1 ||
        res?.subDomain?.toLowerCase()?.indexOf(search.toLowerCase()) != -1
      ) {
        // (res?.records?.map((row) => row.indexOf(search) != -1)) )
        return res;
      }
    });

    let currentTableData = [];
    //console.log(mapData, "dnsDetailsArray");
    if (mapData && mapData.length > 0) {
      const firstPageIndex = (page - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
    }

  // =============== Effect Hooks ===============
    // Update path when location changes
    useEffect(() => {
      setCheckPath(location.search);
    }, [location.search]);
    
    // Fetch data
    useEffect(() => {
      getDnsDetails(id, dispatch);
    }, [id]);

    // Update remediation list when it changes
    useEffect(() => {
      if (remediationList) {
        setAddedRemediations(remediationList.reduce((results, elem) => 
          Object.assign(results, { [elem._id]: elem }), {}));
      }
    }, [remediationList]);

    // Update container class when state is loaded
    useEffect(() => {
      if (dnsDetailsArray) {
        setTimeout(() => {
          setContainerClass("data-container active");
        }, 300);
      }
    }, [dnsDetailsArray]);

  // ========= Handlers / Functions =========
    // Scroll handler
    const executeScroll = (val) => {
      setfilterData(val);
      myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
    };

  // ====== Export/Download Functions ======
    // Export to CSV
    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };
    // Download CSV
    const downloadCsvData = dnsDetailsArray?.map((res) => ({
      DomainName: res?.subDomain,
      RecordType: res?.recordType,
      Record: JSON.stringify(
        res?.recordType == "MX"
          ? res.valid
            ? res?.hosts?.map((row) => row[0]?.hostname)
            : res?.warnings?.map((row) => row?.hostname)
          : res?.record
      )?.replace(/[\[\]']+/g, "")?.replace(/['"]+/g, ""),
      LastUpdated: moment(res?.createdAt).format("DD-MM-YYYY"),
    }));
  // ====== END Export/Download Functions ======

  // ======= Remediation List Bucket =======
    // Remove item from remediation bucket
    const removeToCart = (_id) => e => {
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to remove this from the Remediation List Panel?",
        type: "red",
        buttons: {
          ok: {
            text: "Remove",
            btnClass: "btn-danger",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              removeRemediationData(_id, cartDispatch, id).then((res) => {
                fetchSubDomains(id, page, dispatch, 'all');
              })
            },
          },
          cancel: function () { }
        },
      });
    }
    // Adds item to remediation bucket
    const addToCart = (row) => e => {
      let data = {
        "collectionName": 'deltasubdomaindetails',
        "organisationId": id,
        "recordId": row?._id,
        "moduleName": 'sub_domain'
      }
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to add this to the Remediation List Panel ?",
        type: "green",
        buttons: {
          ok: {
            text: "Add",
            btnClass: "btn-green",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              addRemediationData(data, cartDispatch).then((res) => {
                fetchSubDomains(id, page, dispatch, 'all');
              })
            },
          },
          cancel: function () { }
        },
      });
    }
  // ===== END Remediation List Bucket =====

  return (
    <div>

      {/* <Header type="security" />
      <SideNav type="security" /> */}

      {dnsDetailsArray ?
        <div className={containerClass}>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9'>
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {oldDnsDetails && (
                    <BasicLineColumn
                      dates={oldDnsDetails?.dates}
                      // openPortArray={openPortArray}
                      ipArray={ipArray}
                      color={["#ffd861", "#E15858"]}
                      yAxis={"IP Assets"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <div class="row_boxes row_boxes_table">
                <div class="table_box">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Domain Name</th>
                        <th>Record Type</th>
                        <th>Record</th>
                        {/* <th>Status</th>  Should be before "Last Modified On" */}
                        <th>Last modified on</th>
                        <th>Observation</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              <a href={`https://${row?.subDomain}`} target="_blank">
                                {`${row?.subDomain}`}
                              </a>
                            </td>
                            <td>{row?.recordType}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.recordType == "MX"
                                ? row.valid
                                  ? row?.hosts?.map((req) => {
                                    return (
                                      <>
                                        <span>{req[0]?.hostname}</span>
                                        <br />
                                      </>
                                    );
                                  })
                                  : row?.warnings?.map((req) => {
                                    return (
                                      <>
                                        <span>{req?.hostname}</span>
                                        <br />
                                      </>
                                    );
                                  })
                                : row?.record}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span className="badge badge-success">
                                {moment(row?.updatedAt || row?.createdAt).format("DD-MM-YYYY")}
                              </span>
                            </td>
                            {checkPath == '?mode=assessment-mode' &&  clientType == "Admin" ?
                              <td>
                                {row?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...row,
                                      remediationType: "Sub Domain",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(row?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="dataTables_paginate">
                {mapData && mapData.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={mapData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div> :
        <Loader />
      }

    </div>
  );
}

export default Domain;