/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import GaugeChart from "react-gauge-chart";
import ReactTooltip from "react-tooltip";
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";
import Header from '../resuableComponent/header';
import SideNav from '../resuableComponent/sidenav';
import BasicLineColumn from "../common/apexChart/lineChart";

import AccessPage from "../Shared/accessPage";
import ScanMessage from "../common/scanningMessage";
import img from "../../../src/assets/images/race_png.png"

/* Data */
import {
  fetchSubDomains,
  fetchSubDomainsAction,
  addRemediationData,
  removeRemediationData,
  fetchSubDomainsTotal,
  fetchSubDomainsGraph,
} from "../../actions/sub-domain";


// SSL Certificates PAGE
const Dashboard5 = () => {
    const dispatch = useDispatch();
    const cartDispatch = useDispatch();
    const location = useLocation();
    const myRef = useRef(null);

    const auth = useSelector((state) => state?.auth?.user);
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector((state) => 
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    // no client?
    const subdomainsTotals = useSelector((state) => state?.subDomain?.expiredtotal?.data);
    const scanDetails = useSelector((state) => state?.subDomain?.expiredgraph?.data);
    const subdomains = useSelector((state) => state?.subDomain?.responseexp?.data);
    const subdomainTotal = useSelector((state) => state?.subDomain?.responseexp);
    const remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

  // =============== State Management ===============
    // PAGINATION_AND_DISPLAY
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [containerClass, setContainerClass] = useState("data-container");
    const [firstLoad, setFirstLoad] = useState(true);

    // FILTERING_AND_SEARCH
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("all");
    const [filterData, setfilterData] = useState("all");

    // MODAL_MANAGEMENT
    const [isModal, setModal] = useState("");

    // ACTION_MANAGEMENT
    const [action, setAction] = useState("");
    const [actionNote, setActionNote] = useState("");
    const [forValue, setForValue] = useState("forever");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);
    const [addedRemediations, setAddedRemediations] = useState([]);

    // RISK_ASSESSMENT
    const [riskScore, setRiskScore] = useState("");
    const [expiryLoad, setExpiryLoad] = useState(true);
    const [riskExpiryScore, setExpiryRiskScore] = useState("");

    // IDENTIFIERS_AND_METADATA
    const [lookalikeAppId, setLookalikeAppId] = useState([]);
    const [checkPath, setCheckPath] = useState('');

  // ====== COMPUTED VALUES & CONSTANTS ======
    const modeValue = new URLSearchParams(location?.search).get('mode');

    // Data processing
    let currentTableData = [];
    let mapData = subdomains;
    if (mapData && mapData.length > 0) {
      currentTableData = mapData;
    }
    if (mapData && mapData.length > 0) {
      setTimeout(function () {
        ReactTooltip.rebuild();
      }, 1000);
    }

  // =============== Effect Hooks ===============
    // Update path when location changes
    useEffect(() => {
      setCheckPath(location.search);
    }, [location.search]);

     // Fetch total data
    useEffect(() => {
      fetchSubDomainsTotal(id, dispatch);
    }, [id, dispatch]);
    
    // Fetch graph data
    useEffect(() => {
      fetchSubDomainsGraph(id, dispatch);
    }, [id, dispatch]);
    
    // Fetch data
    useEffect(() => {
      fetchSubDomains(id, page, dispatch, category, search, filterData);
    }, [id, category, page, search, filterData]);

    // Update remediation list when it changes
    useEffect(() => {
      if (remediationList) {
        const updatedRemediations = remediationList.reduce((results, elem) => {
          return { ...results, [elem._id]: elem };
        }, {});
        setAddedRemediations(updatedRemediations);
      }
    }, [remediationList]);

    // Update total items when state changes
    useEffect(() => {
      setTotalItems(subdomainTotal?.totalResults);
    }, [subdomainTotal?.totalResults]);

    // Update container class when state is loaded
    useEffect(() => {
      if (subdomains) {
        setTimeout(() => {
          setContainerClass("data-container active");
        }, 300);
      }
    }, [subdomains]);

  // ========= Handlers / Functions =========
    // Modal handlers
    const addModal = () => {
      setModal("Form Wizard modal");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item._id);
      if (selectedIds.length > 0 || selectedIds.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id, data) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======
    // Handle Snooze action
    const snooze = (_id, event) => {
      if (event == "") {
      }

      if (event == "riskAccepted") {
      }

      if (event == "markAsFalsePositive") {
      }

      if (event == "markAsRemidiated") {
        let ids = [];
        ids.push(_id);
        const data = {
          action: "markAsRemidiated",
          subDomainIds: selectedIds == 0 ? ids : selectedIds,
          actionNote: "",
          for: "forever",
        };
        fetchSubDomainsAction(
          id,
          dispatch,
          page,
          category,
          data
        );
        setSelectedValue("pleaseSelect");
        setActionNote("");
        setSelectedIds([]);
      }
    };
    // Handle Unsnooze action
    const Unsnoozed = (_id) => {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "resetAction",
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
      };
      fetchSubDomainsAction(
        id,
        dispatch,
        page,
        category,
        data
      );
      setSelectedIds([]);
    };
    // Handles Form Submission for Snooze/Unsnooze
    const submit = () => {
      const data = {
        action: action,
        subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
        actionNote: actionNote,
        for: forValue,
      };
      fetchSubDomainsAction(
        id,
        dispatch,
        page,
        category,
        data
      );
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    };
  // ====== END Snooze/Unsooze Function ======

  // ====== Export/Download Functions ======
    // Handle file download from API
    const downloadFile = async () => {
      try {
        const session = localStorage.getItem("session");
        axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
        const response = await axios.get(
          `${API_URL}/expired-ssl-certificates/${id}/excel`,
          { responseType: "blob", }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Expired and Vulnerable SSL.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    };
  // ====== END Export/Download Functions ======

  // ======= Remediation List Bucket =======
    // Remove item from remediation bucket
    const removeToCart = (_id) => e => {
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to remove this from the Remediation List Panel?",
        type: "red",
        buttons: {
          ok: {
            text: "Remove",
            btnClass: "btn-danger",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              removeRemediationData(_id, cartDispatch, id).then((res) => {
                fetchSubDomains(id, page, dispatch, category);
              })
            },
          },
          cancel: function () { }
        },
      });
    }
    // Adds item to remediation bucket
    const addToCart = (row) => e => {
      let data = {
        "collectionName": 'deltasubdomaindetails',
        "organisationId": id,
        "recordId": row?._id,
        "moduleName": 'ssl_certificates'
      }
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to add this to the Remediation List Panel ?",
        type: "green",
        buttons: {
          ok: {
            text: "Add",
            btnClass: "btn-green",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              addRemediationData(data, cartDispatch).then((res) => {
                fetchSubDomains(id, page, dispatch, category);
              })
            },
          },
          cancel: function () { }
        },
      });
    }
  // ===== END Remediation List Bucket =====

return (
  <div>

    {/* <Header type="security" />
    <SideNav type="security" /> */}

    {subdomains ?
      <div className={containerClass}>
        <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
          <div className='col-sm-2'></div>
          <div className='col-sm-9'>
            <div className="row">
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.totalExpiredSSls}
                          </h5>
                          <small className="text-muted color-white">Expired</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.totalSSLsWithRenewalIn30Days}
                          </h5>
                          <small className="text-muted color-white">Renewal in 30 days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-7">
                          <h5 className="mb-0">
                            {subdomainsTotals?.totalSSLsWithRenewalIn60Days}
                          </h5>
                          <small className="text-muted color-white">Renewal in 60 days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-7">
                          <h5 className="mb-0">
                            {subdomainsTotals?.totalSSLsWithRenewalIn90Days}
                          </h5>
                          <small className="text-muted color-white">Renewal in 90 days</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.mismatchedCertificateCount}
                          </h5>
                          <small className="text-muted color-white">Mismatched Certificate</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.revokedCertificateCount}
                          </h5>
                          <small className="text-muted color-white">Revoked Certificate</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.selfSignedCertificateCount}
                          </h5>
                          <small className="text-muted color-white">Self Signed Certificate</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.untrustedCertificateCount}
                          </h5>
                          <small className="text-muted color-white">Untrusted Certificate</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers}
                          </h5>
                          <small className="text-muted color-white">SubDomains Supporting Weak Ciphers</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="card mb-2">
                    <div
                      className="body"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row clearfix">
                        <div className="col-12">
                          <h5 className="mb-0">
                            {subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion}
                          </h5>
                          <small className="text-muted color-white">Subdomains with Outdated TLS Version</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="row_boxes row_boxes_expire">
                  <div className="box">
                    <div className="text_box">
                      <div className="boxA">
                        <h5>{subdomainsTotals?.totalExpiredSSls}</h5>
                        <p>Expired</p>
                      </div>
                      <div className="boxB">
                        <h5>{(
                          subdomainsTotals?.totalExpiredSSls /
                          subdomainsTotals?.totalSSLRecords
                        ).toFixed(0) * 100}
                          %</h5>
                      </div>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="20"
                    >
                      <div
                        className="progress-bar red_progress_bar"
                        style={{
                          width:
                            (
                              subdomainsTotals?.totalExpiredSSls /
                              subdomainsTotals?.totalSSLRecords
                            ).toFixed(0) *
                            100 +
                            "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="text_box">
                      <div className="boxA">
                        <h5>{subdomainsTotals?.totalSSLsWithRenewalIn30Days}</h5>
                        <p>Renewal in 30 days</p>
                      </div>
                      <div className="boxB">
                        <h5>{Math.floor(
                          (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                            subdomainsTotals?.totalSSLRecords) *
                          100
                        )}
                          %</h5>
                      </div>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar red_progress_bar"
                        style={{
                          width:
                            Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                                subdomainsTotals?.totalSSLRecords) *
                              100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="text_box">
                      <div className="boxA">
                        <h5>{subdomainsTotals?.totalSSLsWithRenewalIn60Days}</h5>
                        <p>Renewal in 60 days</p>
                      </div>
                      <div class="boxB">
                        <h5>{Math.floor(
                          (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                            subdomainsTotals?.totalSSLRecords) *
                          100
                        )}
                          %</h5>
                      </div>
                    </div>
                    <div
                      ClassName="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="20"
                    >
                      <div
                        ClassName="progress-bar red_progress_bar"
                        style={{
                          width:
                            Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                                subdomainsTotals?.totalSSLRecords) *
                              100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="text_box">
                      <div className="boxA">
                        <h5>{subdomainsTotals?.totalSSLsWithRenewalIn90Days}</h5>
                        <p>Renewal in 90 days</p>
                      </div>
                      <div className="boxB">
                        <h5>{Math.floor(
                          (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                            subdomainsTotals?.totalSSLRecords) *
                          100
                        )}
                          %</h5>
                      </div>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="20"
                    >
                      <div
                        className="progress-bar red_progress_bar"
                        style={{
                          width:
                            Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                                subdomainsTotals?.totalSSLRecords) *
                              100
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row_boxes row_boxes_security">
                  {/* <div className="box_timer">
                    <div className="img_box">
                      <div class='speed'>
                        <div class='speed__wheel'>
                          <div class='speed__tick'>
                            <div class='tick' value='0'></div>
                            <div class='tick' value='10'></div>
                            <div class='tick' value='20'></div>
                            <div class='tick' value='30'></div>
                            <div class='tick' value='40'></div>
                            <div class='tick' value='50'></div>
                            <div class='tick' value='60'></div>
                            <div class='tick' value='70'></div>
                            <div class='tick' value='80'></div>
                            <div class='tick' value='90'></div>
                            <div class='tick' value='100'></div>
                            <div class='tick' value='110'></div>
                            <div class='tick' value='120'></div>
                            <div class='tick' value='130'></div>
                            <div class='tick' value='140'></div>
                            <div class='tick' value='150'></div>
                            <div class='tick' value='160'></div>
                            <div class='tick' value='170'></div>
                            <div class='tick' value='180'></div>
                            <div class='tick' value='190'></div>
                          </div>
                          <div class='pointer'
                            style={{
                              transform: `rotate(${(-75 + 50)}deg)`
                            }}>
                          </div>
                          <div class='endpoint'></div>
                        </div>
                      </div>
                       <img src={img} className="img-fluid" alt=""/>
                    </div>
                    <div className="text_box_timer">
                      <div className="expired_49">
                        <h3>01 <span>49</span></h3>
                        <p>
                          Expired Certificates
                        </p>
                      </div>
                      <div className="expired_49">
                        <h3>43 <span>49</span></h3>
                        <p>
                          Vulnerable
                          Certificates
                        </p>
                      </div>
                    </div>34
                  </div> */}
                  <div className="graph w-100">
                    {scanDetails && (
                      <BasicLineColumn
                        height={250}
                        dates={scanDetails?.dates}
                        ipArray={scanDetails?.scanDetails}
                        color={["#008ffb", "#00e396"]}
                        yAxis="Unique Vulnerabilities"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
          <div className='col-sm-2'></div>
          <div className='col-sm-9' style={{ padding: '0' }}>
            <div className="row_boxes row_boxes_table">
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      setPage(1);
                      setfilterData("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      setPage(1);
                      setfilterData("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      setPage(1);
                      setfilterData("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      setPage(1);
                      setfilterData("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-sm-4">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={filterData}
                    onChange={(e) => { setfilterData(e.target.value); setPage(1) }}
                  >
                    <option value="all">All</option>
                    <option value="expired">Expired</option>
                    <option value="renewalin30days">
                      Renewal in 30 days
                    </option>
                    <option value="renewalin60days">
                      Renewal in 60 days
                    </option>
                    <option value="renewalin90days">
                      Renewal in 90 days
                    </option>
                    <option value="mismatched">Mismatched</option>
                    <option value="revoked">Revoked</option>
                    <option value="untrusted">Untrusted</option>
                    <option value="self_signed">Self Signed</option>
                    <option value="hasWeakCiphers">Weak Ciphers</option>
                    <option value="hasOutdatedTlsVersions">Outdated TLS Version</option>
                  </select>
                </div>
                <div className="col-sm-4">
                  <div
                    data-test="datatable-input"
                    class="mdb-datatable-filter flex-row"
                  >
                    <input
                      class="form-control form-control-sm ml-0 my-1"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(1);
                      }}
                    />
                  </div>
                </div>
                {modeValue == "report-mode" && <>
                  <div className="col-md-4 col-sm-12 text-right hidden-xs">
                    {currentTableData?.length === 0 ? null : (
                      <span>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          href="/add-report"
                          className="btn btn-primary mr-1"
                          style={{ color: "black" }}
                        >
                          <i className="fa fa-file-excel-o"></i>
                        </a>
                      </span>
                    )}
                  </div>
                </>}
              </div>
              <div className="table_box">
                <table className="table">
                  <thead>
                    <tr>
                    {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedIds == 0 ? false : multiCheckTrue
                                    }
                                    onClick={() => {
                                      multicheck(currentTableData);
                                    }}
                                    className="checkUncheck"
                                  />
                                </th>
                              )}
                      <th>Status</th>
                      <th>Sub-Domain Name</th>
                      <th>Expiry in</th>
                      <th>Country</th>
                      <th>Serial Number</th>
                      <th>Vulnerabilities</th>
                      <th>Last modified on</th>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <th style={{ width: "80" }}>Action</th>
                              )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row) => {
                      return (
                        <tr>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                  category === "risk-accepted" ||
                                  category === "false-positive") && (
                                    <td>
                                      <input
                                        onClick={() => singleClick(row?._id)}
                                        checked={selectedIds?.includes(
                                          row?._id
                                        )}
                                        type="checkbox"
                                        className="checkUncheck"
                                      />
                                    </td>
                                  )}
                          <td>
                            <span
                              className={
                                row?.status == "Active"
                                  ? "badge badge-success"
                                  : "badge badge-danger"
                              }
                            >
                              {row?.status}
                            </span>
                          </td>
                          <td>
                            <span className="text-warning">
                              {row?.subDomainName || "NA"}
                            </span>
                          </td>
                          <td>
                            {row?.expiresInDays + " days" || "NA"}
                          </td>
                          <td>{row?.issuerCountry || "NA"}</td>
                          <td>
                            {row?.certificateSerialNumber || "NA"}
                          </td>
                          <td>
                            {row?.status_vulnerability}
                          </td>
                          <td style={{ whiteSpace: "unset" }}>
                            <span className="badge badge-success">
                              {moment(
                                row?.updatedAt || row?.createdAt
                              ).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          {row?.remediationPending == true ? <td>Retest in progress</td> : <>
                                  {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                    <td>
                                      <Box sx={{ width: 200 }}>
                                        <FormControl fullWidth>
                                          {/* <InputLabel
                                        sx={{ color: "#fff" }}
                                        id="demo-simple-select-label"
                                      >
                                        Action
                                      </InputLabel> */}
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedValue}
                                            onChange={(event) => {
                                              setAction(event.target.value);
                                              snooze(
                                                row?._id,
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <MenuItem value="pleaseSelect">
                                              Snooze Options
                                            </MenuItem>
                                            <MenuItem
                                              value="riskAccepted"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row._id]);
                                              }}
                                            >
                                              Risk Accepted
                                            </MenuItem>
                                            <MenuItem
                                              value="markAsFalsePositive"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row._id]);
                                              }}
                                            >
                                              False Positive
                                            </MenuItem>
                                            <MenuItem value="markAsRemidiated">
                                              Remediated
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </td>
                                  )}
                                </>
                                }
                                {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                  category === "false-positive") && (
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm mr-1 Unsnooze"
                                        onClick={() => Unsnoozed(row?._id)}
                                      >
                                        Unsnooze
                                      </button>
                                    </td>
                                  )}
                          {clientType == "Admin" ?
                            <td>
                              {row?.isTicketChecked == false ? (
                                <button
                                  onClick={addToCart({
                                    ...row,
                                    remediationType: "Sub Domain",
                                  })}
                                >
                                  <span className="fa fa-plus action_icon"></span>
                                </button>
                              ) : (
                                <button onClick={removeToCart(row?._id)}>
                                  <span className="fa fa-minus action_icon"></span>
                                </button>
                              )}
                            </td>
                            : null}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="dataTables_paginate">
              {totalItems > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={totalItems}
                  pageSize={PageSize}
                  onPageChange={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-6 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginRight: "28px" }}
                      >
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          onClick={submit}
                          className="btn btn-round btn-primary mr-1 pull-right"
                        >
                          Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn btn-round btn-default pull-right"
                          style={{ marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> :
      <Loader />
    }

  </div>
);
}

export default Dashboard5;