/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toastr } from "react-redux-toastr";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";

import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import Fancybox from "../../utils/Fancybox";

/* Data */
import {
  brandMention,
  createHOst,
  deleteIp,
  brandMentionAction
} from "../../actions/inventory";
import {
  takeDown
} from "../../actions/look-alike";

// Modal Style Constants and Hooks
// Function to position modal in center of screen
function getModalStyle() { // getModalStyle is a function that returns the style for the modal dialog. It is used to position the modal dialog in the center of the screen.
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Material-UI styles configuration
const useStyles = makeStyles((theme) => ({ // makeStyles is a function that generates a custom hook for creating CSS-in-JS styles. It is used to create custom styles for Material-UI components. It takes a theme object as an argument.
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    minWidth: "600px",
    minHeight: "300px",
  },
}));

// Brand Mentions PAGE
const BrandMention = () => {
    const dispatch = useDispatch();
    const myRef = useRef(null);

    // Redux Selectors
    const auth = useSelector((state) => state?.auth?.user);
    //no clientType?
    const id = useSelector((state) => 
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector((state) => 
      state?.summary?.localClient || state?.summary?.clients?.[0]
    );
    const table = useSelector((state) => state?.inventory?.brand);
    const rating = useSelector((state) => state?.inventory?.brand?.individualRating);
    const scanCompleted = useSelector((state) => state.inventory.scanCompleted);

  // =============== State Management ===============
    // PAGINATION_AND_DISPLAY
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [PageSize, setPerPage] = useState(10);
    const [limit, setLimit] = useState(10);
    const [firstLoad, setFirstLoad] = useState(true);

    // FILTERING_AND_SEARCH
    const [search, setSearch] = useState("");
    const [filterData, setfilterData] = useState("all");
    const [category, setCategory] = useState("all");

    // MODAL_MANAGEMENT
    const [isModal, setModal] = useState(false);
    const [isModalTwo, setModalTwo] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const [modalBody, setModalBody] = useState([]);
    const [modalStyle] = useState(getModalStyle);
    const [modalDetailsOne, setModalDetailsOne] = useState("");
    const [modalDetailsTwo, setModalDetailsTwo] = useState("");
    const [modalDetailsDataOne, setModalDetailsDataOne] = useState("");
    const [modalDetailsDataTwo, setModalDetailsDataTwo] = useState("");
    const [certModal, setCertModal] = useState(false);
    const [certModalBody, setCertModalBody] = useState();
    const [addIpModel, setAddIpModel] = useState(false);
    const [ipRemoveModal, setIpRemoveModal] = useState(false);
    const [isTakeDownModal, setTakeDownModal] = useState("");

    // ACTION_MANAGEMENT
    const [action, setAction] = useState("");
    const [actionNote, setActionNote] = useState("");
    const [forValue, setForValue] = useState("forever");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);

    // FORM_INPUTS
    const [addHost, setHost] = useState("");
    const [stolenLocation, setStolenLocation] = useState("");
    const [contenSource, setContenSource] = useState("");
    const [infringingIp, setInfringingIp] = useState("");
    const [editorContent, setEditorContent] = useState("");
    const [subject, setSubject] = useState("");

    // IDENTIFIERS_AND_METADATA
    const [ipId, setIpId] = useState("");
    const [rowId, setRowId] = useState("");
    const [lookalikeAppId, setLookalikeAppId] = useState([]);

  // ====== COMPUTED VALUES & CONSTANTS ======
    const { orgId } = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const vendorName = sessionStorage.getItem('vendorName');
    const vendorId = sessionStorage.getItem('vendorId');
    const classes = useStyles();

    // Get client domains
    let domains = "";
    client?.domains?.forEach((row) => {
      domains += "," + row;
    });

    // Get client name
    let clientName = "";
    if (JSON.parse(localStorage.getItem("OrgName"))) {
      if (sessionStorage.getItem('vendorId')) {
        clientName = sessionStorage.getItem('vendorName')
      } else {
        clientName = JSON.parse(localStorage.getItem("OrgName"));
      }
    }
  
    let currentTableData = [];
    currentTableData = table;

  // =============== Effect Hooks ===============
    // Fetch brand mentions data
    useEffect(() => {
      const delayedSearch = setTimeout(() => {
        brandMention(id, dispatch, page, category, search, limit);
        }, 500);
      return () => clearTimeout(delayedSearch);
    }, [search, id, page, category, dispatch, limit]);
    
    // Update total brand mentions
    useEffect(() => {
      setTotalItems(table?.totalBrandMentions);
    }, [table?.totalBrandMentions]);
    /////////////////////////////////
  
  // ========= Handlers / Functions =========
    // Host Management - Creates a new host
    const createHost = () => {
      let host = $("#hostName").val();
      if (!host) {
        toastr.error("Error", "Please enter host address.");
      return false;
      }
      createHOst({ organisationId: id, ip: host }, dispatch);
    };

    // Handler for CKEditor changes
    const handleEditorChange = (even, editor) => {
      const data = editor.getData();
      setEditorContent(data); // Update the state with the new content
    };

    // Modal handlers
    const addModal = () => {
      setModalTwo("Form Wizard modal two");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item?._id);
      if (selectedIds?.length > 0 || selectedIds?.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======
    // Handle Snooze action
    const snooze = (_id, event, name) => {
      if (event == "markAsRemidiated") {
        const data = {
          action: "markAsRemidiated",
          brandMentionIds: selectedIds == 0 ? _id : selectedIds,
          actionNote: "",
          for: "forever",
        };
        brandMentionAction(
          id,
          dispatch,
          page,
          category,
          search,
          data
        );
        setSelectedValue("pleaseSelect");
        setActionNote("");
        setSelectedIds([]);
      }
    };
    // Handle Unsnooze action
    const Unsnoozed = (_id) => {
      const data = {
        action: "resetAction",
        brandMentionIds: selectedIds == 0 ? _id : selectedIds,
      };
      brandMentionAction(
        id,
        dispatch,
        page,
        category,
        search,
        data
      );
      setSelectedIds([]);
    };
    // Handles Form Submission for Snooze/Unsnooze
    const submit = () => {
      const data = {
        action: action,
        brandMentionIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
        actionNote: actionNote,
        for: forValue
      };
      brandMentionAction(
        id,
        dispatch,
        page,
        category,
        search,
        data
      );
      setModalTwo("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    };
  // ====== END Snooze/Unsooze Function ======

  // ====== Export/Download Functions ======
    // Exports data to CSV in new format
    const exportToCSVNew = (fileName) => {
      const downloadExcel = Object.keys(modalBody).map((val) => {
        let value = modalBody[val];
        if (fileName == "Hosts Location") {
          return {
            HostsLocation: val?.replace(/['"]+/g, ""),
            IP: JSON.stringify(value.map((res) => res))
              ?.replace(/[\[\]']+/g, "")
              ?.replace(/['"]+/g, ""),
          };
        } else if (fileName == "Cloud / ISP Provider") {
          return {
            CloudProvider: val?.replace(/['"]+/g, ""),
            IP: JSON.stringify(value.map((res) => res))
              ?.replace(/[\[\]']+/g, "")
              ?.replace(/['"]+/g, ""),
          };
        } else if (fileName == "Network Services") {
          return {
            NetworkServices: val?.replace(/['"]+/g, ""),
            IP: JSON.stringify(value.map((res) => res))
              ?.replace(/[\[\]']+/g, "")
              ?.replace(/['"]+/g, ""),
          };
        }
      });
      const ws = XLSX.utils.json_to_sheet(downloadExcel);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    // Handle file download from API
    const downloadFile = async () => {
      try {
        const session = localStorage.getItem("session");
        axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
        const response = await axios.get(
          `${API_URL}/brand-mentions/${id}/excel`, 
          { responseType: "blob", }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${clientName?.replace(/\s+/g, '-')}Brand Mention.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    };
  // ====== END Export/Download Functions ======

  // ==== Takedown Request Function ====
    // Prepares takedown form with initial values
    const takeDownValue = (res) => {
      let ip;
      res?.dnsDetails?.map((row) => {
        return Object.keys(row || {}).map((key) => {
          let record = row[key];
          if (record && record.length == 0) return false;
          if (key !== "dns_a") return false;
          record?.map((val) => {
            ip = val;
          });
        });
      });
      let originalWebsite = client.website;
      let domainName = res.host;
      ip = res.ip;
      setStolenLocation(originalWebsite);
      setContenSource(domainName);
      setInfringingIp(ip);
      setRowId(res._id);
      setTakeDownModal(true);
    };
    // Submit takedown request
    const submitTakeDownValue = () => {
      if (!subject) {
        toastr.error("Error", "Subject cannot be empty.");
        return false;
      }
      if (!stolenLocation) {
        toastr.error("Error", "Stolen location cannot be empty.");
        return false;
      }
      if (!contenSource) {
        toastr.error("Error", "Content source cannot be empty.");
        return false;
      }
      if (!infringingIp) {
        toastr.error("Error", "Infringin IP cannot be empty.");
        return false;
      }
      if (!editorContent) {
        toastr.error("Error", "Editor content cannot be empty.");
        return false;
      }
      setTakeDownModal(false);
      takeDown(
        {
          subject,
          stolenLocation,
          contenSource,
          infringingIp,
          editorContent,
          rowId,
          id,
          page: "mobile"
        },
        dispatch
      );
    };
  // ==== END Takedown Request Function ====

  // ==== UI COMPONENTS & MODAL CONTENT ====
    const modalBodyData = (
      <div style={modalStyle} className={classes.paper}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setAddIpModel(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div>
          <h2>Add Targets</h2>
          <div>
            <div className="row">
              <label className="col-form-label col-md-4">Host Name</label>
              <div className="col-md-7">
                <input
                  type="text"
                  className="form-control"
                  // style={{ width: 200 }}
                  id="hostName"
                  placeholder="Add Host"
                  value={addHost}
                  onChange={(e) => setHost(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-right mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => createHost()}
                  >
                    Add Host
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  // ==== END UI COMPONENTS & MODAL CONTENT ====
  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <Modal
        open={addIpModel}
        onClose={() => setAddIpModel(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyData}
      </Modal>
      
      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
          <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("all");
                  setPage(1);
                  setSearch("");
                  setSelectedIds([]);
                }}
              >
                Current
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("risk-accepted");
                  setPage(1);
                  setSearch("");
                  setSelectedIds([]);
                }}
              >
                Risk Accepted
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("false-positive");
                  setPage(1);
                  setSearch("");
                  setSelectedIds([]);
                }}
              >
                False Positive
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("remidiated");
                  setPage(1);
                  setSearch("");
                  setSelectedIds([]);
                }}
              >
                Remediated
              </a>
            </li>
          </ul>
                <div className="row clearfix">
                  <div className="col-sm-3">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setPage(1);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>

                <div className="row_boxes row_boxes_table">
                <div className="table_box table_box_full">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData?.brandMentions);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>Sub Domain Name</th>
                        <th>Brand Impersonation Analysis</th>
                        <th>Suspected Website Analysis</th>
                        <th>Impersonation Score</th>
                        <th>Last Updated on</th>
                        <th>Take Down</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.brandMentions?.map((row) => {
                        return (
                          <tr>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(
                                      row?._id
                                    )}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>
                              {row?.subDomainName}
                            </td>
                            <td className="cursor"
                              onClick={() => {
                                setModalDetailsDataOne(row?.brandImpersonationAnalysis);
                                setModalDetailsOne(true);
                              }}>Details</td>
                            <td className="cursor" onClick={() => {
                              setModalDetailsDataTwo(row?.suspectedWebsiteAnalysis);
                              setModalDetailsTwo(true);
                            }}>Details</td>
                            <td>
                              {row?.impersonationScore || "N/A"}
                            </td>
                            <td
                            >
                              {moment(row?.updatedAt).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>
                              {row?.requestStatus && row?.requestStatus != "" ? (
                                row?.requestStatus
                              ) : (
                                <button
                                  className="btn btn-danger btn-sm mr-1"
                                  onClick={() => takeDownValue(row)}
                                >
                                  Request
                                  <br />
                                  Takedown
                                </button>
                              )}
                            </td>
                            {row?.remediationPending == true ? <td>Retest in progress</td> : <>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(
                                            row?._id
                                          );
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row?._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row?._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                            </>
                            }
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() =>
                                      Unsnoozed(
                                        row?._id
                                      )
                                    }
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  </div>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={limit}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${isModal ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalHeading}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div className="row">
                    <div className="col-sm-12">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          exportToCSVNew(modalHeading);
                        }}
                        href="/add-report"
                        style={{ float: "right" }}
                        className="btn btn-primary mr-1"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card">
                    {/* <div className="body"> */}
                    <div className="table-responsive">
                      <table className="table table-hover table-custom spacing5">
                        <thead>
                          <tr>
                            {Object.keys(modalBody).map((val) => {
                              return (
                                <th style={{ width: "200" }}>
                                  {val?.replace(/['"]+/g, "")}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.keys(modalBody).map((val) => {
                              let value = modalBody[val];
                              return (
                                <td style={{ verticalAlign: "top" }}>
                                  {value?.map((row) => {
                                    return (
                                      <>
                                        <span>{row}</span>
                                        <br />
                                      </>
                                    );
                                  })}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade Form-Wizard-modal ${certModal ? "d-block show" : ""
        }`}>
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Certificate
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setCertModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div className="card" dangerouslySetInnerHTML={{ __html: certModalBody?.replace(/\n/g, "<br />") }}>
                    {/* {certModalBody}
                    dangerouslySetInnerHTML={{__html: alert.alertContent}} */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade  ${ipRemoveModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setIpRemoveModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setIpRemoveModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  deleteIp(ipId, id, true, dispatch);
                  setIpRemoveModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${isTakeDownModal ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Website Takedown Request
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setTakeDownModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card card_ck">
                    <div className="body">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={stolenLocation}
                            onChange={(e) => setStolenLocation(e.target.value)}
                            placeholder="Stolen Location"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={contenSource}
                            onChange={(e) => setContenSource(e.target.value)}
                            placeholder="Content Source"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={infringingIp}
                            onChange={(e) => setInfringingIp(e.target.value)}
                            placeholder="Infringing IP"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <label>Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editorContent}
                          onChange={handleEditorChange}
                          config={{
                            styles: [
                              `
              .ck-editor__editable {
                min-height: 200px; /* Set your desired minimum height */
              }
              p, h1, h2, h3 {
                color: black; /* Set text color to black */
              }
            `,
                            ],
                          }}
                        />
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button
                          className="btn btn-danger btn-sm mr-1 pull-right"
                          // to={''}
                          style={{ marginTop: "10px" }}
                          onClick={() => submitTakeDownValue()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${modalDetailsOne ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Brand Impersonation Analysis
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalDetailsOne(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Conclusion</th>
                        <th>Mention Type</th>
                        <th>Mentions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-wrap">
                          {modalDetailsDataOne?.conclusion || "N/A"}
                        </td>
                        <td>
                          {modalDetailsDataOne?.mention_type || "N/A"}
                        </td>
                        <td className="text-wrap">
                          {modalDetailsDataOne?.mentions?.map((r) => {
                            return <span>{r || "N/A"}</span>
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${modalDetailsTwo ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Suspected Website Analysis
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalDetailsTwo(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Url</th>
                        <th>Full Page Screenshot</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a href={modalDetailsDataTwo?.url} target="_blank">
                            {modalDetailsDataTwo?.url || "N/A"}
                          </a>
                        </td>
                        <td>
                          {modalDetailsDataTwo?.full_page_screenshot ?
                            <Fancybox
                              options={{
                                Carousel: {
                                  infinite: false,
                                },
                              }}
                            >
                              <a
                                data-fancybox="gallery"
                                href={modalDetailsDataTwo?.full_page_screenshot}
                              >
                                <img
                                  alt=""
                                  src={modalDetailsDataTwo?.full_page_screenshot}
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                    objectFit: 'cover'
                                  }}
                                />
                              </a>
                            </Fancybox>
                            : "N/A"}
                        </td>
                        <td className="text-wrap">
                          {modalDetailsDataTwo?.description || "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${isModalTwo === "Form Wizard modal two" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalTwo("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModalTwo("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BrandMention;