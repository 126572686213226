import React, { Component, useEffect, useState } from "react";
import { Link, useLocation, } from "react-router-dom";
import askAI from '../../assets/images/Ask_Ai_Icon.svg'
import { useSelector } from "react-redux";


const BottomNav = (props) => {

    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);
    const params = new URLSearchParams(location?.search);
    let modeValue = useSelector(
        (state) =>
          state?.summary?.modes?.data || "assessment-mode"
      );

     useEffect(() => {
         setPathname(location?.pathname);
         console.log(location?.pathname, 'pathname')
     }, [location?.pathname]);

    const MenuTitle = {
        // Remediation Mode
        "/remediation-mode/overview": { 
            title: "Remediation Mode", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Remediation Overview", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Lorem Ipsum",
            recommendation: "Lorem Ipsum"
        },
        "/remediation-mode/task": { 
            title: "Remediation Mode", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Remediation Tasks", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Lorem Ipsum", 
            recommendation: "Lorem Ipsum"
        },
        "/remediation-mode/tickets": { 
            title: "Remediation Mode", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Remediation Tickets", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Lorem Ipsum",
            recommendation: "Lorem Ipsum"
        },
        "/remediation-mode/trusted-user": { 
            title: "Remediation Mode", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Trusted Users", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Lorem Ipsum", 
            recommendation: "Lorem Ipsum"
        },
        "/remediation-mode/activity-log": { 
            title: "Remediation Mode", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Activity Log", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg",
            description: "Lorem Ipsum", 
            recommendation: "Lorem Ipsum"
        },/*
        "/client": { 
            title: "Security Healcheck", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Asset Monitoring", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Asset monitoring involves continuously tracking and inventorying an organization's digital assets, including hardware, software, and cloud resources. This process helps maintain an up-to-date view of the IT infrastructure, identifying new, changed, or potentially vulnerable assets. Effective asset monitoring enables better security management, compliance adherence, and rapid response to potential threats or misconfigurations.", 
            recommendation: "Implement an automated asset discovery and management system to maintain an accurate, real-time inventory. Regularly audit and update your asset database, including both on-premises and cloud resources. Integrate asset monitoring with security tools to quickly identify and address vulnerabilities or compliance issues across your entire infrastructure." 
        },
        "/show-details/:id": { 
            title: "Security Healcheck", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Asset Monitoring", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Asset monitoring involves continuously tracking and inventorying an organization's digital assets, including hardware, software, and cloud resources. This process helps maintain an up-to-date view of the IT infrastructure, identifying new, changed, or potentially vulnerable assets. Effective asset monitoring enables better security management, compliance adherence, and rapid response to potential threats or misconfigurations.", 
            recommendation: "Implement an automated asset discovery and management system to maintain an accurate, real-time inventory. Regularly audit and update your asset database, including both on-premises and cloud resources. Integrate asset monitoring with security tools to quickly identify and address vulnerabilities or compliance issues across your entire infrastructure." 
        },*/
        // Asset Monitoring
        "/assets/": { 
            title: "Asset Monitoring ", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Asset Monitoring", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Asset monitoring involves continuously tracking and inventorying an organization's digital assets, including hardware, software, and cloud resources. This process helps maintain an up-to-date view of the IT infrastructure, identifying new, changed, or potentially vulnerable assets. Effective asset monitoring enables better security management, compliance adherence, and rapid response to potential threats or misconfigurations.", 
            recommendation: "Implement an automated asset discovery and management system to maintain an accurate, real-time inventory. Regularly audit and update your asset database, including both on-premises and cloud resources. Integrate asset monitoring with security tools to quickly identify and address vulnerabilities or compliance issues across your entire infrastructure." 
        },
        /*"/assets": { 
            title: "Asset Monitoring ", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Asset Monitoring", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "Asset monitoring involves continuously tracking and inventorying an organization's digital assets, including hardware, software, and cloud resources. This process helps maintain an up-to-date view of the IT infrastructure, identifying new, changed, or potentially vulnerable assets. Effective asset monitoring enables better security management, compliance adherence, and rapid response to potential threats or misconfigurations.", 
            recommendation: "Implement an automated asset discovery and management system to maintain an accurate, real-time inventory. Regularly audit and update your asset database, including both on-premises and cloud resources. Integrate asset monitoring with security tools to quickly identify and address vulnerabilities or compliance issues across your entire infrastructure." 
        },*/
        // Security Healcheck
        "/security": { 
            title: "Security Healcheck", 
            icon: "/static/media/HOSTSDISC_Dashboard.c9c06099.svg", 
            subTitle: "Domain & DNS Health Check", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg", 
            description: "A Domain & DNS Health Check is a comprehensive assessment of a domain's configuration and DNS records to ensure they are correctly set up and functioning properly.", 
            recommendation: "To enhance your domain's security and email deliverability, enable and correctly configure DNSSEC to prevent DNS spoofing, regularly monitor its status, and manage keys effectively. For email security, create an SPF record specifying all authorized mail servers, keeping it simple and regularly validating it. Implement DMARC starting with a p=none policy to monitor traffic, then gradually enforce stricter policies (p=quarantine, p=reject). Use DMARC aggregate reports to monitor email authentication and ensure alignment with SPF and DKIM. Regularly review and adjust these settings to maintain robust protection against spoofing and phishing attempts." 
        },
        "/security/dns-health": {
            title: "Security Healcheck", 
            icon: "/assets/images/image-gallery/security_health.svg", 
            subTitle: "DNS Health Check", 
            subIcon: "/assets/images/image-gallery/domain_dns.svg",
            description: "A DNS Health Check is a comprehensive assessment of a domain's configuration and DNS records to ensure they are correctly set up and functioning properly.",
            recommendation: "To enhance your domain's security and email deliverability, enable and correctly configure DNSSEC to prevent DNS spoofing, regularly monitor its status, and manage keys effectively. For email security, create an SPF record specifying all authorized mail servers, keeping it simple and regularly validating it. Implement DMARC starting with a p=none policy to monitor traffic, then gradually enforce stricter policies (p=quarantine, p=reject). Use DMARC aggregate reports to monitor email authentication and ensure alignment with SPF and DKIM. Regularly review and adjust these settings to maintain robust protection against spoofing and phishing attempts.",
            faq: "What is DNSSEC?\nDNSSEC (Domain Name System Security Extensions) is a security protocol that adds an extra layer of security to your DNS. It helps prevent DNS spoofing and cache poisoning by ensuring the authenticity and integrity of the DNS records.\nHow do I enable DNSSEC for my domain?\n\nTo enable DNSSEC, you need to check if your domain registrar supports it. If they do, you can enable it through your domain registrars control panel. Ensure that you correctly configure DNSSEC and regularly monitor its status.\n\nWhat is an SPF record?\n\nAn SPF (Sender Policy Framework) record is a DNS record that specifies which mail servers are authorized to send emails on behalf of your domain. It helps prevent email spoofing and improves email deliverability.\nHow do I create an SPF record?\nTo create an SPF record, list all authorized mail servers in a TXT record in your DNS settings. Ensure the SPF record is concise and includes all your email-sending services."
        },
        "/security/sub-domain": { 
            title: "Security Healcheck", 
            icon: "/assets/images/image-gallery/security_health.svg", 
            subTitle: "Subdomain & Dangling Subdomain", 
            subIcon: "/assets/images/image-gallery/host_server.svg", 
            description: "Subdomain enumeration is the process of discovering all subdomains associated with a main domain. This technique can reveal forgotten or improperly managed subdomains. Dangling subdomains occur when a subdomain's DNS record points to a non-existent or expired resource, potentially allowing attackers to claim and control them. Identifying these issues helps prevent subdomain takeover attacks and unauthorized access to organization assets.", 
            recommendation: "Regularly audit and maintain an updated inventory of all subdomains, removing or updating obsolete DNS records. Implement strong access controls and security measures for all active subdomains. Use automated monitoring tools to detect potential subdomain takeover vulnerabilities and respond promptly to any identified issues." 
        },
        "/security/known-vulnerabilities": { 
            title: "Security Healcheck", 
            icon: "/assets/images/image-gallery/security_health.svg", 
            subTitle: "Known Vulnerabilities", 
            subIcon: "/assets/images/image-gallery/known_vulberability.svg", 
            description: "Passive IP vulnerability scanning identifies potential security weaknesses in internet-facing systems by analyzing publicly available information about IP addresses. It detects known vulnerabilities (CVEs) associated with open ports and services, helping organizations understand their external attack surface without actively probing target systems.", 
            recommendation: "Regularly conduct passive scans to identify exposed services and potential vulnerabilities. Promptly patch or update systems with known CVEs, and implement a robust vulnerability management program. Consider using network segmentation and firewall rules to limit unnecessary exposure of services to the internet." 
        },
        "/security/ssl-certificates": { 
            title: "Security Healcheck", 
            icon: "/assets/images/image-gallery/security_health.svg", 
            subTitle: "SSL Certificates", 
            subIcon: "/assets/images/image-gallery/ssl_certificate.svg", 
            description: "SSL certificates encrypt data transmitted between websites and users, ensuring secure connections. Expired or vulnerable SSL certificates can expose communications to interception and compromise website security. Regular scans can identify outdated, misconfigured, or weak SSL/TLS implementations, helping prevent potential man-in-the-middle attacks and maintaining user trust.", 
            recommendation: "Regularly monitor and renew SSL certificates before expiration, and automate the renewal process where possible. Use strong, up-to-date encryption protocols and ciphers, disabling outdated versions. Conduct periodic SSL/TLS vulnerability scans and promptly address any identified weaknesses or misconfigurations in your certificate deployments." 
        },
        "/security/web-technology": { 
            title: "Security Healcheck", 
            icon: "/assets/images/image-gallery/security_health.svg", 
            subTitle: "Outdated Web Technologies", 
            subIcon: "/assets/images/image-gallery/web_technologies.svg", 
            description: "Outdated web technologies are software components no longer supported or containing known vulnerabilities. These legacy elements may include old versions of content management systems, web servers, or programming languages. Identifying them helps pinpoint security risks, as they often have unpatched vulnerabilities with available exploits.", 
            recommendation: "Regularly audit your web infrastructure and maintain an up-to-date inventory of all software versions. Implement a robust patch management system to promptly apply security updates. Consider replacing or upgrading legacy systems that are no longer supported, and conduct regular vulnerability assessments to identify potential risks." 
        },
        // Brand Security
        "/brand": { 
            title: "Brand Security", 
            icon: "/assets/images/image-gallery/brand_security.svg", 
            subTitle: "Fake Mobile Application", 
            subIcon: "/assets/images/image-gallery/Icon.svg", 
            description: "Fake mobile applications are malicious software disguised as legitimate apps. These counterfeit versions of popular apps are often distributed through unofficial channels or even app stores, aiming to trick users into downloading them. Once installed, they can steal sensitive data, display intrusive ads, or perform other harmful actions on the user's device.", 
            recommendation: "Only download apps from official app stores and verify developer information before installing. Keep devices and operating systems updated, and use mobile security software for added protection. Educate users about the risks of fake apps and how to identify suspicious applications before downloading." 
        },
        "/brand/brand-mention": { 
            title: "Brand Security", 
            icon: "/assets/images/image-gallery/brand_security.svg", 
            subTitle: "Brand Mentions", 
            subIcon: "/assets/images/image-gallery/Icon.svg", 
            description: "Lorem Ipsum", 
            recommendation: "Lorem Ipsum" 
        },
        "/brand/mobile-apps": { 
            title: "Brand Security", 
            icon: "/assets/images/image-gallery/brand_security.svg", 
            subTitle: "Fake Mobile Application", 
            subIcon: "/assets/images/image-gallery/Icon.svg", 
            description: "Fake mobile applications are malicious software disguised as legitimate apps. These counterfeit versions of popular apps are often distributed through unofficial channels or even app stores, aiming to trick users into downloading them. Once installed, they can steal sensitive data, display intrusive ads, or perform other harmful actions on the user's device.", 
            recommendation: "Only download apps from official app stores and verify developer information before installing. Keep devices and operating systems updated, and use mobile security software for added protection. Educate users about the risks of fake apps and how to identify suspicious applications before downloading." 
        },
        "/brand/look-alike": { 
            title: "Brand Security", 
            icon: "/assets/images/image-gallery/brand_security.svg", 
            subTitle: "Look-alike Application", 
            subIcon: "/assets/images/image-gallery/Icon_1.svg", 
            description: "Look-alike domains are web addresses designed to mimic legitimate websites, often using similar spellings, characters, or top-level domains. These deceptive sites are created for phishing attacks, brand impersonation, or distributing malware, exploiting user confusion to steal sensitive information or damage an organization's reputation.", 
            recommendation: "Regularly monitor and register similar domain variations of your brand name. Implement strong authentication methods and educate employees and customers about identifying legitimate sites. Consider using domain protection services and pursue legal action against malicious domain owners when necessary." 
        },
        "/brand/social-media": { 
            title: "Brand Security", 
            icon: "/assets/images/image-gallery/brand_security.svg", 
            subTitle: "Fake Social Media", 
            subIcon: "/assets/images/image-gallery/Icon_Selected.svg", 
            description: "Fake social media accounts impersonate brands, individuals, or organizations on platforms like Facebook, Twitter, and LinkedIn. These fraudulent profiles often aim to mislead followers, spread disinformation, or conduct phishing attacks. They can damage reputations, manipulate public opinion, and potentially compromise user security through malicious links or scams.", 
            recommendation: "Regularly monitor social platforms for impersonators and report fraudulent profiles to the respective platforms for takedown. Implement verified account status where available and educate your audience about your official accounts. Consider using brand protection services to assist with detection and streamline the takedown process across multiple platforms." 
        },
        // Breach Detection
        /*"/breach": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Stolen Credentials", 
            subIcon: "/assets/images/image-gallery/stolen_credentials.svg", 
            description: "Employees use their official IDs to log in to third-party systems, potentially exposing sensitive access credentials. Through dark web scanning, we've identified leaked credentials associated with our organization. We promptly report these findings to our remediation team to mitigate security risks and protect company assets.", 
            recommendation: "Remediation involves immediately revoking compromised credentials and requiring affected employees to reset their passwords using strong, unique combinations. The process also includes implementing additional security measures like multi-factor authentication and providing targeted security awareness training to prevent future incidents." 
        },*/
        "/breach/stolen-credentials": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Stolen Credentials", 
            subIcon: "/assets/images/image-gallery/stolen_credentials.svg", 
            description: "Employees use their official IDs to log in to third-party systems, potentially exposing sensitive access credentials. Through dark web scanning, we've identified leaked credentials associated with our organization. We promptly report these findings to our remediation team to mitigate security risks and protect company assets.", 
            recommendation: "Remediation involves immediately revoking compromised credentials and requiring affected employees to reset their passwords using strong, unique combinations. The process also includes implementing additional security measures like multi-factor authentication and providing targeted security awareness training to prevent future incidents." 
        },
        "/breach/malware-credentials": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Malware Infected Computers", 
            subIcon: "/assets/images/image-gallery/malware_infected_computers.svg", 
            description: "Malware like Redline and MetaStealer infect computers to extract sensitive data, including login credentials. These stealers typically harvest passwords, cookies, and other account information from browsers and applications, storing the stolen data in logs for later retrieval by attackers.", 
            recommendation: "Isolate the device, run a full antivirus scan, and remove detected malware. Change all passwords for potentially compromised accounts from a clean device and enable two-factor authentication where available." 
        },
        "/breach/source-code": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Source Code Leakage", 
            subIcon: "/assets/images/image-gallery/source_code_leakage.svg", 
            description: "Source code leakage refers to unauthorized disclosure of proprietary software code. This can occur through insider threats, misconfigured repositories, or cyberattacks, potentially exposing intellectual property and security vulnerabilities to competitors or malicious actors.", 
            recommendation: "Private source code repositories like GitHub, BitBucket, and GitLab can be vulnerable to leaks if misconfigurated or compromised. Unauthorized access to these platforms may expose sensitive code, potentially revealing proprietary algorithms, security flaws, or intellectual property to competitors or malicious actors." 
        },
        "/breach/leaked-sessions": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Leaked Sessions", 
            subIcon: "/assets/images/image-gallery/leaked_session.svg", 
            description: "Leaked sessions from stealer malware involve the unauthorized capture and exfiltration of user authentication tokens or cookies. These stolen session data allow attackers to impersonate legitimate users, potentially gaining unauthorized access to accounts and sensitive information without needing passwords.", 
            recommendation: "Implement multi-factor authentication and regularly rotate session tokens. Use secure, HTTP-only cookies and employ robust endpoint security measures. Educate users about the risks of malware and the importance of maintaining updated, secure systems." 
        },
        "/breach/cloud-bucket": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Open Cloud Buckets", 
            subIcon: "/assets/images/image-gallery/open_cloud_buckets.svg", 
            description: "Open or misconfigured cloud storage buckets can expose sensitive organizational data to unauthorized access. These improperly secured buckets on platforms like AWS S3, Google Cloud Storage, or Azure Blob Storage may contain confidential files, backups, or customer information that become publicly accessible, posing significant security and compliance risks.", 
            recommendation: "Immediately review and restrict bucket access permissions, ensuring only authorized users can view contents. Implement proper authentication, encrypt sensitive data, and regularly audit bucket configurations. Use cloud security tools to monitor for misconfigurations and automate security policy enforcement across your cloud infrastructure." 
        },
        "/breach/ip-blacklist": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "IP Blacklists", 
            subIcon: "/assets/images/image-gallery/ip_blacklist.svg", 
            description: "IP blacklisting identifies potentially malicious IP addresses associated with spam, malware, or other threats. By cross-referencing an organization's public IPs against threat intelligence databases, the solution can detect compromised servers or systems engaging in suspicious activities, helping to mitigate risks and maintain network security.", 
            recommendation: "Investigate and remediate compromised systems associated with blacklisted IPs. Implement robust network security measures, including firewalls and intrusion detection systems. Regularly monitor your IP reputation, maintain clean email practices, and work with your ISP or hosting provider to remove legitimate IPs from blacklists if necessary." 
        },
        "/breach/pil": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Personal Information Leakage", 
            subIcon: "/assets/images/image-gallery/open_cloud_buckets.svg", 
            description: "Lorem Ipsum",
            recommendation: "Lorem Ipsum" 
        },
        "/breach/pastebin": { 
            title: "Breach Detection", 
            icon: "/assets/images/image-gallery/breach.svg", 
            subTitle: "Pastebin Search", 
            subIcon: "/assets/images/image-gallery/open_cloud_buckets.svg", 
            description: "Lorem Ipsum",
            recommendation: "Lorem Ipsum" 
        }
    }


    const tabUpDown = (e) => {
        e.preventDefault();
        var tab_uppper_button = document.getElementById('tab_uppper_button');
        var mypanel = document.getElementById('bottom_panel_ID');
        tab_uppper_button.classList.toggle('active_circle');
        mypanel.classList.toggle('active_bottom_panel');
    }

    const onTabClick = (tabId) => (e) => {
        console.log(e.target, 'e.target');
        let buttons = document.getElementsByClassName('nav-link');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove('active');
        }
        document.getElementById(tabId).classList.toggle('active');

        let tabs = document.getElementsByClassName('tab-pane');
        for (var i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove('show');
            tabs[i].classList.remove('active');
        }
        document.getElementById(tabId + '-pane').classList.toggle('show');
        document.getElementById(tabId + '-pane').classList.toggle('active');
    }

    return (
        <>
            <div class="bottom_panel" id="bottom_panel_ID">
                <div class="bottom_panel_header">
                    <div class="boxA">
                        <div class="upper_box">
                            <img src={MenuTitle[pathname]?.icon} class="img-fluid" alt="" />
                            <div class="text_box">
                                <p>{MenuTitle[pathname]?.title}</p>
                                <div
                                    class="progress"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    <div
                                        class={`progress-bar ${modeValue == 'remediation-mode' ?
                                            'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                        style={{ width: "100%" }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="inner_content">
                            <p>{MenuTitle[pathname]?.subTitle}</p>
                            <img src={MenuTitle[pathname]?.subIcon} class="img-fluid" alt="" />
                        </div>
                    </div>

                    <div class="tab_boxes">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                    <div class="inner_tab_button">
                                        <img src="../assets/images/image-gallery/description.svg" class="img-fluid" alt="" />
                                        <p>Description</p>
                                    </div>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                    <div class="inner_tab_button">
                                        <img src="../assets/images/image-gallery/guidance.svg" class="img-fluid" alt="" />
                                        <p>Guidance</p>
                                    </div>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                    <div class="inner_tab_button">
                                        <img src={askAI} class="img-fluid" alt="" />
                                        <p>ASK AI</p>
                                    </div>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation" style={{ visibility: "hidden" }}>
                                <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" disabled onClick={onTabClick("disabled-tab")}>
                                    <div class="inner_tab_button">
                                        <img src="../assets/images/image-gallery/solution_center.svg" class="img-fluid" alt="" />
                                        {/* <p>Solution Center</p> */}
                                    </div>
                                </button>
                            </li>
                        </ul>

                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                <p className="larger-text">
                                    {MenuTitle[pathname]?.description}
                                </p>
                            </div>
                            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                <p className="larger-text">
                                    {MenuTitle[pathname]?.recommendation}
                                </p>
                            </div>
                            <div class="tab-pane fade tab-chat show active"
                                id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                <iframe
                                    src="https://app.thinkstack.ai/bot/index.html?chatbot_id=66eba55a8c9ace6deadd1202"
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    style={{ minHeight: '390px' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="boxA boxB">
                        <div class="upper_box">
                            <div class="text_box">
                                <span>Current Mode:</span>
                                <p> {modeValue == 'remediation-mode' ?
                                    'REMEDIATION' : modeValue == 'assessment-mode' ?
                                        'ASSESSMENT' : modeValue == 'settings-mode' ? 'Settings' : 'ASSESSMENT'} </p>
                                <div
                                    class="progress"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    <div
                                        class={`progress-bar ${modeValue == 'remediation-mode' ?
                                            'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                        style={{ width: "100%" }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="inner_content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="up_down_circle up_down_circle_bottom" id="tab_uppper_button">
                <a onClick={tabUpDown}>
                    <img src="../assets/images/image-gallery/arrow_icon.png" class="img-fluid" alt="" />
                    <img src="../assets/images/image-gallery/arrow_icon.png" class="img-fluid" alt="" />
                </a>
            </div>

        </>
    )
}

export default BottomNav;