/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";
import GaugeChart from "react-gauge-chart"; 

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";
import Header from "../resuableComponent/header";
import SideNav from "../resuableComponent/sidenav";
import BasicLineColumn from "../common/apexChart/lineChart";

import BasicColumn from "../common/apexChart/basicLineColumn";
// import BasicColumn from "../common/apexChart/basicColumn";
import TrialComponent from "../common/trialComponent"; 
import NoData from "../common/noDataComponetnt"; 
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage"; 

/* Data */
import {
  fetchIpInventory,
  fetchIpInventoryAction,
  fetchIpInventoryGraph,
} from "../../actions/inventory";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";


// IP Blacklist PAGE
const IPBlacklist = () => {
        const dispatch = useDispatch();
        const cartDispatch = useDispatch();
        const location = useLocation();

        const auth = useSelector((state) => state?.auth?.user);
        const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
        const id = useSelector((state) =>
            state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
        );
        const client = useSelector((state) => 
            state?.summary?.localClient || state?.summary?.clients?.data?.[0]
        );
        const state = useSelector((state) => state?.inventory?.list);
        const scanDetails = useSelector((state) => state?.inventory?.ipblackgraph?.data);
        const isLoaded = useSelector((state) => state?.inventory?.loaded);
        const scanCompleted = useSelector((state) => state?.inventory?.scanCompleted);
        const remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

    // =============== State Management ===============
        // PAGINATION_AND_DISPLAY
        const [page, setPage] = useState(1);
        const [PageSize, setPerPage] = useState(10);
        const [totalItems, setTotalItems] = useState(0);
        const [containerClass, setContainerClass] = useState("data-container");

        // FILTERING_AND_SEARCH
        const [filterData, setfilterData] = useState("all");
        const [category, setCategory] = useState("all");

        // MODAL_MANAGEMENT
        const [isModal, setModal] = useState("");

        // ACTION_MANAGEMENT
        const [forValue, setForValue] = useState("forever");
        const [actionNote, setActionNote] = useState("");
        const [addedRemediations, setAddedRemediations] = useState([]);
        const [action, setAction] = useState("");
        const [selectedValue, setSelectedValue] = useState("pleaseSelect");
        const [selectedIds, setSelectedIds] = useState([]);
        const [multiCheckTrue, setMultiCheckTrue] = useState(false);

        // IDENTIFIERS_AND_METADATA
        const [lookalikeAppId, setLookalikeAppId] = useState([]);
        const [checkPath, setCheckPath] = useState('');

    // ====== COMPUTED VALUES & CONSTANTS ======
        const modeValueCheck = new URLSearchParams(location?.search).get('mode');
        
        let compliantValue = [], // ???
        ipArray = []; // ???

        // Data Processing
        let mapData = state?.data;
        let currentTableData = mapData && mapData.length > 0 ? mapData : [];
            
    // =============== Effect Hooks ===============
        // Update path when location changes
        useEffect(() => {
            setCheckPath(location.search)
        }, [location.search])
        
        // Fetch graph data
        useEffect(() => {
            fetchIpInventoryGraph(id, dispatch);
        }, [id, dispatch]);

        // Fetch data when dependencies change
        useEffect(() => {
            fetchIpInventory(id, dispatch, page, category, filterData);
        }, [id, page, category, filterData]);

        // Update remediations when list changes
        useEffect(() => {
            if (remediationList) {
            const updatedRemediations = remediationList.reduce((results, elem) => {
                return { ...results, [elem._id]: elem };
            }, {});
            setAddedRemediations(updatedRemediations);
            }
        }, [remediationList]);

        // Update total items when state changes
        useEffect(() => {
            setTotalItems(state?.totalIps)
        }, [state?.totalIps])

        // Update container class when state is loaded
        useEffect(() => {
            if (!isLoaded) {
            setTimeout(() => {
                setContainerClass("data-container active");
            }, 300);
            }
        }, [isLoaded]);

  // ========= Handlers / Functions =========
        // Modal handlers
        const addModal = () => {
            setModal("Form Wizard modal");
        };

    // ====== Checkbox Handlers ======
        // Multiple item selection handler
        const multicheck = (data) => {
            const allIds = data?.map((item) => item._id);
            if (selectedIds.length > 0 || selectedIds.length == 0) {
            setMultiCheckTrue(true);
            setSelectedIds(allIds);
            }
            if (multiCheckTrue == true) {
            setSelectedIds([]);
            setMultiCheckTrue(false);
            }
        };
        // Single item selection handler
        const singleClick = (id) => {
            setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter(
                    (selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
            });
            if (selectedIds.length > 10) {
            setMultiCheckTrue(true);
            } else {
            setMultiCheckTrue(false);
            }
        };
    // ====== END Checkbox Handlers ======

    // ====== Snooze/Unsooze Function ======
        // Handle Snooze action
        const snooze = (_id, event) => {
            if (event === "markAsRemidiated") {
            let ids = [];
            ids.push(_id);
            const data = {
                action: "markAsRemidiated",
                blackListedIPIds: selectedIds == 0 ? ids : selectedIds,
                actionNote: "",
                for: "forever",
            };
            fetchIpInventoryAction(id, dispatch, page, category, data);
            setSelectedValue("pleaseSelect");
            setActionNote("");
            setSelectedIds([]);
            }
        };
        // Handle Unsnooze action
        const Unsnoozed = (_id) => {
            let ids = [];
            ids.push(_id);
            const data = {
            action: "resetAction",
            blackListedIPIds: selectedIds == 0 ? ids : selectedIds,
            };
            fetchIpInventoryAction(id, dispatch, page, category, data);
            setSelectedIds([]);
        };
        // Handles Form Submission for Snooze/Unsnooze
        const submit = () => {
            const data = {
            action: action,
            blackListedIPIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
            actionNote: actionNote,
            for: forValue,
            };
            fetchIpInventoryAction(id, dispatch, page, category, data);
            setModal("");
            setSelectedValue("pleaseSelect");
            setActionNote("");
            setSelectedIds([]);
        };
    // ====== END Snooze/Unsooze Function ======

    // ====== Export/Download Functions ======
        // Handle file download from API
        const downloadFile = async () => {
            try {
            const session = localStorage.getItem("session");
            axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
            const response = await axios.get(
                `${API_URL}/black-listed-ips/${id}/excel`,
                { responseType: "blob", }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Ip Blacklisted.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            } catch (error) {
            console.error(error);
            }
        };
    // ====== END Export/Download Functions ======

    // ======= Remediation List Bucket =======
        // Remove item from remediation bucket
        const removeToCart = (_id) => e => {
            $.confirm({
            title: "Are you sure?",
            content: "Do you really want to remove this from the Remediation List Panel?",
            type: "red",
            buttons: {
                ok: {
                text: "Remove",
                btnClass: "btn-danger",
                keys: ["enter"],
                action: function () {
                    e.preventDefault();
                    removeRemediationData(_id, cartDispatch, id).then((res) => {
                    fetchIpInventory(id, dispatch, page, category);
                    })
                },
                },
                cancel: function () { },
            },
            });
        }
        // Adds item to remediation bucket
        const addToCart = (row) => e => {
            let data = {
            "collectionName": 'deltaipdetails',
            "organisationId": id,
            "recordId": row?._id,
            "moduleName": "ip_blacklist"
            }
            $.confirm({
            title: "Are you sure?",
            content: "Do you really want to add this to the Remediation List Panel ?",
            type: "green",
            buttons: {
                ok: {
                text: "Add",
                btnClass: "btn-green",
                keys: ["enter"],
                action: function () {
                    e.preventDefault();
                    addRemediationData(data, cartDispatch).then((res) => {
                    fetchIpInventory(id, dispatch, page, category);
                    })
                },
                },
                cancel: function () { },
            },
            });
        }
    // ===== END Remediation List Bucket =====

  return (
    <div>
      {/* <Header type="breach" />
      <SideNav type="breach" /> */}

      {!isLoaded ? (
        <div className={containerClass}>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {scanDetails && (
                    <BasicLineColumn
                      dates={scanDetails?.dates}
                      ipArray={scanDetails?.scanDetails}
                      color={["#6574cd"]}
                      yAxis="Records Found"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      setPage(1);
                      setfilterData("all");
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      setPage(1);
                      setfilterData("all");
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      setPage(1);
                      setfilterData("all");
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      setPage(1);
                      setfilterData("all");
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-sm-4">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={filterData}
                    onChange={(e) => { setfilterData(e.target.value); setPage(1) }}
                  >
                    <option value="all">All</option>
                    <option value="blacklisted">Blacklisted</option>
                    <option value="not-blacklisted">Not Blacklisted</option>
                  </select>
                </div>
                {modeValueCheck == "report-mode" && <>
                  <div className="col-md-8 text-right hidden-xs">
                    <span>
                      {client?.subscriptionType != "trial" && currentTableData?.length !== 0 && (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          href="/add-report"
                          style={{ color: "black", fontWeight: "bold" }}
                          className="btn btn-primary mr-1"
                        >
                          <i
                            className="fa fa-file-excel-o"
                            style={{ color: "black" }}
                          ></i>
                        </a>
                      )}
                    </span>
                  </div>
                </>}
              </div>
              <div className="row_boxes row_boxes_table">
                <div className="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>Status</th>
                        <th>Host Name</th>
                        <th>Cloud / ISP Provider</th>
                        <th>Location</th>
                        <th>Blacklist Database</th>
                        <th>Last modified on</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        // //console.log(ror,row)
                        let services = [];
                        row?.services?.map((res, index) => {
                          if (services.indexOf(res?.service_name) == -1)
                            services.push(res?.service_name);
                        });
                        return (
                          <tr>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(row?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td style={{ whiteSpace: "unset" }}>
                              {/* <span>{index + 1}</span> */}
                              <span
                                className={
                                  row?.isBlackListed == true
                                    ? "badge badge-critical"
                                    : "badge badge-low"
                                }
                              >
                                {row?.isBlackListed == true
                                  ? "Blacklisted"
                                  : "Not blacklisted"}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <div className="d-flex align-items-center">
                                <div
                                  className="ml-3"
                                  style={{
                                    color: "#ffffff",
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  {row?.ip}
                                </div>
                              </div>
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.cloudOrISPProvider}
                            </td>

                            <td style={{ whiteSpace: "unset" }}>
                              {row?.location}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.blackListSource?.map((row, index) => {
                                return (
                                  <span
                                    id={`ipblacklist${index}`}
                                    data-tip={row}
                                    className="badge badge-warning"
                                  >
                                    {row}
                                  </span>
                                );
                              })}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span className="badge badge-success">
                                {moment(
                                  row?.updatedAt || row?.createdAt
                                ).format("DD-MM-YYYY")}
                              </span>
                            </td>
                            {row?.remediationPending == true ? <td>Retest in progress</td> :
                              <>
                                {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                  <td>
                                    <Box sx={{ width: 200 }}>
                                      <FormControl fullWidth>
                                        {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectedValue}
                                          onChange={(event) => {
                                            setAction(event.target.value);
                                            snooze(row?._id, event.target.value);
                                          }}
                                        >
                                          <MenuItem value="pleaseSelect">
                                            Snooze Options
                                          </MenuItem>
                                          <MenuItem
                                            value="riskAccepted"
                                            onClick={() => {
                                              addModal();
                                              setLookalikeAppId([row._id]);
                                            }}
                                          >
                                            Risk Accepted
                                          </MenuItem>
                                          <MenuItem
                                            value="markAsFalsePositive"
                                            onClick={() => {
                                              addModal();
                                              setLookalikeAppId([row._id]);
                                            }}
                                          >
                                            False Positive
                                          </MenuItem>
                                          <MenuItem value="markAsRemidiated">
                                            Remediated
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </td>
                                )}
                              </>
                            }
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {clientType == "Admin" ?
                              <td>
                                {row?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...row,
                                      remediationType: "IP Blacklist",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(row?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">
                            Snooze for
                          </label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default IPBlacklist;