import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import QuantumOverview from '../Quantum/QuantumReadiness.js';
import QuantumDomains from '../Quantum/QuantumReadiness_domains.js';
import QuantumSystems from '../Quantum/QuantumReadiness_systems.js';
import QuantumApplications from '../Quantum/QuantumReadiness_applications.js';
import QuantumFiles from '../Quantum/QuantumReadiness_files.js';
import QuantumTraffic from '../Quantum/QuantumReadiness_traffic.js';
import QuantumCode from '../Quantum/QuantumReadiness_code.js';

const Quantum = ({match}) => {
    console.log(match,' Quantum')
    return (
        <>
            <Switch>
                <Route path={`${match.path}/overview`} component={QuantumOverview} />
                <Route path={`${match.path}/domain`} component={QuantumDomains} />
                <Route path={`${match.path}/system`} component={QuantumSystems} />
                <Route path={`${match.path}/application`} component={QuantumApplications} />
                <Route path={`${match.path}/file`} component={QuantumFiles} />
                <Route path={`${match.path}/traffic`} component={QuantumTraffic} />
                <Route path={`${match.path}/code`} component={QuantumCode} />
                <Route path={`${match.path}`} component={QuantumOverview} />
            </Switch>
        </>
    )
}

export default Quantum;