/* Libraries / React Components / Node_Modules */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";

/* Local Imports */
import Pagination from "../Pagination/Pagination";
import { API_URL } from "../../utils/constant";
import Loader from "../resuableComponent/loader";
import Header from "../resuableComponent/header";
import SideNav from "../resuableComponent/sidenav";
import BasicLineColumn from "../common/apexChart/lineChart";

/* Data */
import { 
  fetchLeakedCredentials, 
  fetchLeakedCredentialsAction, 
  fetchLeakedCredentialsGraph 
} from "../../actions/leakedCredentials";
import {
  addRemediationData,
  removeRemediationData
} from "../../actions/sub-domain";


// Stolen Credentials PAGE
const StolenCredentials = () => {
    const dispatch = useDispatch();
    const cartDispatch = useDispatch();
    const location = useLocation();

    const auth = useSelector((state) => state?.auth?.user);
    const clientType = useSelector((state) => state?.auth?.user?.accessLevel);
    const id = useSelector((state) => 
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector((state) => 
      state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );
    const state = useSelector((state) => state?.leakedCredential?.response?.data);
    const remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);
    const domainValue = useSelector((state) => state?.leakedCredential?.response?.data?.domains);
    const oldDnsDetails = useSelector((state) => state.leakedCredential?.leakedgraph?.data);

  // =============== State Management ===============
    // PAGINATION_AND_DISPLAY
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [containerClass, setContainerClass] = useState("data-container");

    // FILTERING_AND_SEARCH
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("all");
    const [filterValue, setFilterValue] = useState("all");
    const [domainvalue, setDomains] = useState("");
    const [domainSelected, setDomainSelected] = useState("all");

    // MODAL_MANAGEMENT
    const [isModal, setModal] = useState("");

    // ACTION_MANAGEMENT
    const [forValue, setForValue] = useState("forever");
    const [actionNote, setActionNote] = useState("");
    const [selectedValue, setSelectedValue] = useState("pleaseSelect");
    const [lookalikeAppId, setLookalikeAppId] = useState([]);
    const [action, setAction] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [multiCheckTrue, setMultiCheckTrue] = useState(false);
    const [addedRemediations, setAddedRemediations] = useState([]);

    // UI_STATE
    const [toggleArray, setToggleArray] = useState({});

    // IDENTIFIERS_AND_METADATA
    const [checkPath, setCheckPath] = useState('');

  // ====== COMPUTED VALUES & CONSTANTS ======
    const modeValue = new URLSearchParams(location?.search).get('mode');

    // Data processing
    let ipArray = [];
    for (let details = 0; details < oldDnsDetails?.scanDetails?.length; details++) {
      ipArray.push(oldDnsDetails?.scanDetails[details]?.length)
    }

    let totalIp = 0, plainPasswords = 0;
    if (oldDnsDetails?.scanDetails) {
      totalIp = oldDnsDetails?.ipLength || 0;
      plainPasswords = oldDnsDetails?.plainPassword || 0;
    }

    let response = state?.data;
    response = response?.sort((a, b) =>
      a.dec > b.dec ? -1 : b.dec > a.dec ? 1 : 0
    );

    let mapData = response;
    let currentTableData = [];
    if (mapData && mapData.length > 0) {
      currentTableData = mapData;
    }

  // =============== Effect Hooks ===============
    // Update check path when location changes
    useEffect(() => {
      setCheckPath(location.search)
    }, [location.search])

    // Fetch total data
    useEffect(() => {
      setTotalItems(state?.totalLeakedCredentials)
    }, [state?.totalLeakedCredentials])
    
    // Fetch graph data
    useEffect(() => {
      fetchLeakedCredentialsGraph(id, dispatch)
    }, [id, dispatch])
    
    // Fetch data when dependencies change
    useEffect(() => {
      fetchLeakedCredentials(id, dispatch, page, category, filterValue, search, domainSelected, domainvalue);
    }, [id, dispatch, page, category, filterValue, search, domainSelected, domainvalue]);
    
    // Update remediation list when it changes
    useEffect(() => {
      if (remediationList) {
        const updatedRemediations = remediationList.reduce((results, elem) => {
          return { ...results, [elem._id]: elem };
        }, {});
        setAddedRemediations(updatedRemediations);
      }
    }, [remediationList]);

    // Update container class when state is loaded
    useEffect(() => {
      if (state) {
        setTimeout(() => {
          setContainerClass("data-container active");
        }, 300);
      }
    }, [state]);

  // ========= Handlers / Functions =========
    // Handles search input changes
    const handleSearchChange = (e) => {
      setSearch(e.target.value);
      setPage(1)
    };

    // Toggle password visibility
    const toggleIcon = (_id, value) => {
      let val = toggleArray;
      if (val[_id]) {
        $("#" + _id + "_password").html("******");
        $("#" + _id + "_password_icon").attr('class', 'fa fa-eye');
        delete val[_id];
        setToggleArray({ ...val });
      } else {
        $("#" + _id + "_password").html(value);
        $("#" + _id + "_password_icon").attr('class', 'fa fa-eye-slash');
        val[_id] = true;
        setToggleArray({ ...val });
      }
    };

    // Modal handlers
    const addModal = () => {
      setModal("Form Wizard modal");
    };

  // ====== Checkbox Handlers ======
    // Multiple item selection handler
    const multicheck = (data) => {
      const allIds = data?.map((item) => item._id);
      if (selectedIds.length > 0 || selectedIds.length == 0) {
        setMultiCheckTrue(true);
        setSelectedIds(allIds);
      }
      if (multiCheckTrue == true) {
        setSelectedIds([]);
        setMultiCheckTrue(false);
      }
    };
    // Single item selection handler
    const singleClick = (id, data) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter(
            (selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
      if (selectedIds.length > 10) {
        setMultiCheckTrue(true);
      } else {
        setMultiCheckTrue(false);
      }
    };
  // ====== END Checkbox Handlers ======

  // ====== Snooze/Unsooze Function ======
    // Handle Snooze action
    const snooze = (_id, event) => {
      if (event == "markAsRemidiated") {
        let ids = [];
        ids.push(_id);
        const data = {
          action: "markAsRemidiated",
          leakedCredentialsIds: selectedIds == 0 ? ids : selectedIds,
          actionNote: "",
          for: "forever",
        };
        fetchLeakedCredentialsAction(id, dispatch, page, category, data);
        setSelectedValue("pleaseSelect");
        setActionNote("");
        setSelectedIds([]);
      }
    };
    // Handle Unsnooze action
    const Unsnoozed = (_id) => {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "resetAction",
        leakedCredentialsIds: selectedIds == 0 ? ids : selectedIds,
      };
      fetchLeakedCredentialsAction(id, dispatch, page, category, data);
      setSelectedIds([]);
    };
    // Handles Form Submission for Snooze/Unsnooze
    const submit = () => {
      const data = {
        action: action,
        leakedCredentialsIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
        actionNote: actionNote,
        for: forValue,
      };
      fetchLeakedCredentialsAction(id, dispatch, page, category, data);
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    };
  // ====== END Snooze/Unsooze Function ======

  // ====== Export/Download Functions ======
    // Handle file download from API
    const downloadFile = async () => {
      try {
        const session = localStorage.getItem("session");
        axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
        const response = await axios.get(
          `${API_URL}/dehashed/search/${id}/excel`,
          { responseType: "blob", }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "StolenCredentials.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    };
  // ====== END Export/Download Functions ======  
  
  // ======= Remediation List Bucket =======
    // Remove item from remediation bucket
    const removeToCart = (_id) => e => {
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to remove this from the Remediation List Panel?",
        type: "red",
        buttons: {
          ok: {
            text: "Remove",
            btnClass: "btn-danger",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              removeRemediationData(_id, cartDispatch, id).then(() => {
                fetchLeakedCredentials(id, dispatch, page, category);
              })
            },
          },
          cancel: function () { },
        },
      });
    }
    // Adds item to remediation bucket
    const addToCart = (row) => e => {
      let data = {
        "collectionName": 'deltaleakedcredentials',
        "organisationId": id,
        "recordId": row?._id,
      }
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to add this to the Remediation List Panel ?",
        type: "green",
        buttons: {
          ok: {
            text: "Add",
            btnClass: "btn-green",
            keys: ["enter"],
            action: function () {
              e.preventDefault();
              addRemediationData(data, cartDispatch).then(() => {
                fetchLeakedCredentials(id, dispatch, page, category);
              })
            },
          },
          cancel: function () { },
        },
      });
    }
  // ===== END Remediation List Bucket =====

  return (
    <div>
      {state ? (
        <div className={containerClass}>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9'>
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {oldDnsDetails && (
                    <BasicLineColumn
                      dates={oldDnsDetails?.dates}
                      ipArray={oldDnsDetails?.scanDetails}
                      color={["#6574cd"]}
                      yAxis="Records Found"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      setPage(1);
                      setFilterValue("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      setPage(1);
                      setFilterValue("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      setPage(1);
                      setFilterValue("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      setPage(1);
                      setFilterValue("all");
                      setSearch("");
                      setSelectedIds([]);
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-sm-4">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={filterValue}
                    onChange={(e) => { setFilterValue(e.target.value); setPage(1) }}
                  >
                    <option value="all">All</option>
                    <option value="plain-password">Plain Password</option>
                    <option value="encrypted-password">Encrypted Password</option>
                  </select>
                </div>
                <div className="col-sm-4">
                  <div
                    data-test="datatable-input"
                    class="mdb-datatable-filter flex-row"
                  >
                    <input
                      class="form-control form-control-sm ml-0 my-1"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                {domainSelected == 'filterOnlyRecordswithEmlSrcDomain' || domainSelected == 'filterEmlDomain'
                  || domainSelected == 'filterSrcDomain'
                  ?
                  <>
                    <div className="col-sm-4 mt-2">
                      <select
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => setDomains(e.target.value)}
                      >
                        <option value="">
                          Please Select Domain
                        </option>
                        {domainValue?.map((r) => (
                          <option key={r} value={r}>
                            {r}
                          </option>
                        ))}
                      </select>
                    </div>
                  </> : null
                }
                {modeValue == "report-mode" && <>
                  <div className="col-md-1 mt-1  text-left hidden-xs">
                    <span>
                      {client?.subscriptionType != "trial" && mapData?.length !== 0 && (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          href="/add-report"
                          style={{ color: "black", fontWeight: "bold" }}
                          className="btn btn-primary mr-1"
                        >
                          <i
                            className="fa fa-file-excel-o"
                            style={{ color: "black" }}
                          ></i>
                        </a>
                      )}
                    </span>
                  </div></>}
              </div>
              <div className="row_boxes row_boxes_table">
                <div className="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>Password Type</th>
                        <th>Email Address</th>
                        <th>Password</th>
                        <th>Source</th>
                        <th>Description</th>
                        <th>Date</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        let password = row?.pwd?.split("") || ["N", "A"];
                        let date = row?.imp
                          ? row?.imp?.toString().substr(0, 4) +
                          "-" +
                          row?.imp?.toString().substr(4, 2) +
                          "-" +
                          row?.imp?.toString().substr(6, 2)
                          : "NA";
                        return (
                          <tr key={index}>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(row?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>
                              <span
                                className={
                                  row?.dec == 0
                                    ? `badge badge-success ml-0 mr-0`
                                    : `badge badge-danger ml-0 mr-0`
                                }
                              >
                                {row?.dec == 0 ? "Decrypted" : "Plain Password"}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "unset", width: "100px" }}>
                              {row?.eml || "NA"}
                            </td>
                            <td style={{ width: "100px" }}>
                              <span
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                  display: "flex"
                                }}
                              >
                                <span id={`${row._id}_password`}>******</span>
                                <i class="fa fa-eye" style={{ marginLeft: "10px", cursor: "pointer", fontSize: "16px" }} id={`${row._id}_password_icon`} onClick={() => toggleIcon(row._id, password)}></i>
                              </span>
                            </td>
                            <td>{row?.src || "NA"}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.desc || "NA"}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>{moment(row?.createdAt).format("DD-MM-YYYY") || "NA"}</td>
                            {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                              <td>
                                <Box sx={{ width: 200 }}>
                                  <FormControl fullWidth>
                                    {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedValue}
                                      onChange={(event) => {
                                        setAction(event.target.value);
                                        snooze(row?._id, event.target.value);
                                      }}
                                    >
                                      <MenuItem value="pleaseSelect">
                                        Snooze Options
                                      </MenuItem>
                                      <MenuItem
                                        value="riskAccepted"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([row._id]);
                                        }}
                                      >
                                        Risk Accepted
                                      </MenuItem>
                                      <MenuItem
                                        value="markAsFalsePositive"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([row._id]);
                                        }}
                                      >
                                        False Positive
                                      </MenuItem>
                                      <MenuItem value="markAsRemidiated">
                                        Remediated
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                            )}
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {clientType == "Admin" ?
                              <td>
                                {row?.isTicketChecked == false ? <button onClick={addToCart({ ...row, remediationType: "Stolen Credentials" })}>
                                  <span className="fa fa-plus action_icon"></span>
                                </button> : <button onClick={removeToCart(row?._id)}>
                                  <span className="fa fa-minus action_icon"></span>
                                </button>}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">Snooze for</label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default StolenCredentials;